import { Button } from "@/components/ui/button";
import { FormLabel } from "@/components/ui/form";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { Control } from "react-hook-form";
import { Plus, Trash2, Edit } from "lucide-react";
import { useFieldArray } from "react-hook-form";
import { useState } from "react";
import { VariationDialog } from "./VariationDialog";
import { VariationFormValues, FoodItemFormValues } from "@/types";
import { useLocalizedText } from "@/utils/textual";
import { useTranslation } from "@/hooks/useTranslation";

interface VariationsFieldProps {
  control: Control<FoodItemFormValues>;
}

export function VariationsField({ control }: VariationsFieldProps) {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "variations",
  });
  const getLocalizedText = useLocalizedText();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleVariationSubmit = (values: VariationFormValues) => {
    if (editingIndex !== null) {
      update(editingIndex, values);
      setEditingIndex(null);
    } else {
      append(values);
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setDialogOpen(true);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <FormLabel>{t('forms.labels.variations')}</FormLabel>
        <InfoTooltip content={t('forms.tooltips.variations')} />
      </div>

      {fields.map((field, index) => {
        const variation = field as unknown as VariationFormValues;
        const localizedText = getLocalizedText(variation.textualData);
        return (
          <div key={field.id} className="p-4 border rounded-lg space-y-4">
            <div className="flex justify-between items-start">
              <div>
                <h4 className="text-sm font-medium">
                  {localizedText.title || `Variation ${index + 1}`}
                </h4>
                {localizedText.description && (
                  <p className="text-sm text-muted-foreground">{localizedText.description}</p>
                )}
                <p className="text-sm">€{variation.price}</p>
              </div>
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleEdit(index)}
                >
                  <Edit className="h-4 w-4" />
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => remove(index)}
                  className="text-destructive"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        );
      })}

      <Button
        type="button"
        variant="outline"
        onClick={() => {
          setEditingIndex(null);
          setDialogOpen(true);
        }}
        className="w-full"
      >
        <Plus className="h-4 w-4 mr-2" />
        {t('forms.labels.variations')}
      </Button>

      <VariationDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        onSubmit={handleVariationSubmit}
        initialData={editingIndex !== null ? fields[editingIndex] as unknown as VariationFormValues : undefined}
      />
    </div>
  );
}