import { useLanguage } from "@/contexts/LanguageContext";
import { translations } from "@/i18n/translations";

export function useTranslation() {
  const { uiLanguage } = useLanguage();
  
  const t = (path: string) => {
    console.log(`Translating path: ${path} for language: ${uiLanguage}`);
    const keys = path.split('.');
    let current: any = translations[uiLanguage];
    
    for (const key of keys) {
      if (current[key] === undefined) {
        console.warn(`Translation missing for key: ${path} in language: ${uiLanguage}`);
        // Try to fall back to English if the translation is missing
        let fallback = translations.EN;
        for (const fallbackKey of keys) {
          if (fallback[fallbackKey] === undefined) {
            return path; // If even English translation is missing, return the key
          }
          fallback = fallback[fallbackKey];
        }
        return fallback;
      }
      current = current[key];
    }
    
    return current;
  };

  return { t };
}