import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { TimeFields } from "@/components/promotions/form-sections/TimeFields";
import { WeekDaysField } from "@/components/promotions/form-sections/WeekDaysField";
import { BasicInfoFields } from "./form-sections/BasicInfoFields";
import { PhotoFields } from "./form-sections/PhotoFields";
import { mockStaffClient } from "@/api/mockStaffClient";
import { useEffect } from "react";
import { WeekDay, LocalizedTitleAndDescription } from "@/types";

const formSchema = z.object({
  id: z.string().optional(),
  textualData: z.array(z.object({
    language: z.enum(["EN", "DE", "FR", "IT"]),
    title: z.string().min(1, "Title is required"),
    description: z.string().min(1, "Description is required"),
  })).min(1, "At least one language is required"),
  startDay: z.string().optional(),
  endDay: z.string().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  weekDays: z.array(z.enum(["MO", "TU", "WE", "TH", "FR", "SA", "SU"])).optional(),
  foodItemIds: z.array(z.string()).min(1, "At least one food item is required"),
  photoUrls: z.array(z.string()).default([]),
});

export type MenuFormValues = z.infer<typeof formSchema>;

interface MenuFormProps {
  initialData?: MenuFormValues;
  isEditing?: boolean;
  menuId?: string;
}

export function MenuForm({ initialData, isEditing = false, menuId }: MenuFormProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  
  const form = useForm<MenuFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData || {
      id: undefined,
      textualData: [{
        language: "EN",
        title: "",
        description: "",
      }],
      startDay: "",
      endDay: "",
      startTime: "",
      endTime: "",
      weekDays: [],
      foodItemIds: [],
      photoUrls: [],
    },
  });

  useEffect(() => {
    const loadMenuData = async () => {
      if (isEditing && menuId) {
        try {
          console.log("Loading menu data for ID:", menuId);
          const menu = await mockStaffClient.getMenu(menuId);
          if (menu) {
            console.log("Loaded menu data:", menu);
            const formData = {
              id: menu.id,
              textualData: menu.textualData,
              startDay: menu.validityPeriods[0]?.startDay || "",
              endDay: menu.validityPeriods[0]?.endDay || "",
              startTime: menu.validityPeriods[0]?.startTime?.slice(0, -3) || "",
              endTime: menu.validityPeriods[0]?.endTime?.slice(0, -3) || "",
              weekDays: (menu.validityPeriods[0]?.weekDays || []) as WeekDay[],
              foodItemIds: menu.foodItemIds,
              photoUrls: menu.photoUrl ? [menu.photoUrl] : [],
            };
            form.reset(formData);
          }
        } catch (error) {
          console.error("Error loading menu:", error);
          toast({
            title: "Error",
            description: "Failed to load menu data. Please try again.",
            variant: "destructive",
          });
        }
      }
    };

    loadMenuData();
  }, [isEditing, menuId, form, toast]);

  async function onSubmit(values: MenuFormValues) {
    console.log("Menu form submitted with values:", values);

    const apiData = {
      textualData: values.textualData,
      validityPeriods: [{
        startDay: values.startDay ? `--${values.startDay.split('-')[1]}-${values.startDay.split('-')[2]}` : undefined,
        endDay: values.endDay ? `--${values.endDay.split('-')[1]}-${values.endDay.split('-')[2]}` : undefined,
        startTime: values.startTime ? `${values.startTime}:00` : undefined,
        endTime: values.endTime ? `${values.endTime}:00` : undefined,
        weekDays: values.weekDays as WeekDay[],
        zoneId: "CET" as const,
      }],
      foodItemIds: values.foodItemIds,
      photoUrl: values.photoUrls[0],
    };

    try {
      console.log("Sending API data:", apiData);
      if (isEditing && menuId) {
        await mockStaffClient.updateMenu(menuId, apiData);
      } else {
        await mockStaffClient.createMenu(apiData);
      }

      toast({
        title: `Menu ${isEditing ? 'updated' : 'created'}`,
        description: `Your menu has been ${isEditing ? 'updated' : 'created'} successfully.`,
      });
      
      navigate("/menus");
    } catch (error) {
      console.error('API error:', error);
      toast({
        title: "Error",
        description: `Failed to ${isEditing ? 'update' : 'create'} menu. Please try again.`,
        variant: "destructive",
      });
    }
  }

  const handlePhotoUploaded = (url: string) => {
    const currentPhotos = form.getValues("photoUrls") || [];
    form.setValue("photoUrls", [...currentPhotos, url]);
  };

  const handlePhotoRemoved = (urlToRemove: string) => {
    const currentPhotos = form.getValues("photoUrls") || [];
    form.setValue("photoUrls", currentPhotos.filter(url => url !== urlToRemove));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <BasicInfoFields control={form.control} />
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <TimeFields control={form.control} />
        </div>
        
        <WeekDaysField control={form.control} />

        <PhotoFields 
          control={form.control}
          menuId={initialData?.id}
          onPhotoUploaded={handlePhotoUploaded}
          onPhotoRemoved={handlePhotoRemoved}
        />
        
        <div className="flex justify-end gap-4">
          <Button variant="outline" type="button" onClick={() => navigate("/menus")}>
            Cancel
          </Button>
          <Button type="submit">{isEditing ? 'Update' : 'Create'} Menu</Button>
        </div>
      </form>
    </Form>
  );
}