export const mockMenus = [
  {
    id: "1",
    textualData: [
      {
        language: "EN" as const,
        title: "Lunch Menu",
        description: "Available during lunch hours"
      }
    ],
    validityPeriods: [{
      startTime: "11:00:00",
      endTime: "15:00:00",
      weekDays: ["MO", "TU", "WE", "TH", "FR"],
      startDay: "--01-01",
      endDay: "--12-31",
      zoneId: "CET"
    }],
    foodItemIds: ["1", "2"],
    photoUrl: null
  }
];