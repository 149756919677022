import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { Control } from "react-hook-form";

// These values come from the OpenAPI spec (FoodAllergen enum)
const ALLERGENS = [
  "EGGS",
  "MILK",
  "FISH",
  "SHELLFISH",
  "TREE_NUTS",
  "PEANUTS",
  "WHEAT",
  "SOYBEANS"
] as const;

interface AllergensFieldProps {
  control: Control<any>;
}

export function AllergensField({ control }: AllergensFieldProps) {
  return (
    <FormField
      control={control}
      name="allergens"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex items-center gap-2">
            Allergens
            <InfoTooltip content="Select all allergens present in this food item" />
          </FormLabel>
          <div className="grid grid-cols-2 gap-4 mt-2">
            {ALLERGENS.map((allergen) => (
              <FormField
                key={allergen}
                control={control}
                name="allergens"
                render={({ field }) => (
                  <FormItem
                    key={allergen}
                    className="flex flex-row items-start space-x-3 space-y-0"
                  >
                    <Checkbox
                      checked={(field.value || []).includes(allergen)}
                      onCheckedChange={(checked) => {
                        const currentValues = field.value || [];
                        const newValue = checked
                          ? [...currentValues, allergen]
                          : currentValues.filter((value) => value !== allergen);
                        field.onChange(newValue);
                      }}
                    />
                    <FormLabel className="font-normal cursor-pointer">
                      {allergen.replace('_', ' ')}
                    </FormLabel>
                  </FormItem>
                )}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}