import { LocalizedTitleAndDescription } from "@/types";
import { useLanguage } from "@/contexts/LanguageContext";

export function getLocalizedText(textualData: LocalizedTitleAndDescription[], defaultLanguage: "EN" | "DE" | "FR" | "IT" = "EN") {
  const item = textualData.find(item => item.language === defaultLanguage) || textualData[0];
  return {
    title: item?.title || "",
    description: item?.description || ""
  };
}

export function useLocalizedText() {
  const { defaultLanguage } = useLanguage();
  
  return (textualData: LocalizedTitleAndDescription[]) => 
    getLocalizedText(textualData, defaultLanguage as "EN" | "DE" | "FR" | "IT");
}