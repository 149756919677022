import { FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Control } from "react-hook-form";
import { MenuFormValues } from "../MenuForm";
import { PhotoUpload } from "@/components/shared/PhotoUpload";

export function PhotoFields({ 
  control, 
  menuId,
  onPhotoUploaded,
  onPhotoRemoved 
}: { 
  control: Control<MenuFormValues>;
  menuId?: string;
  onPhotoUploaded: (url: string) => void;
  onPhotoRemoved: (url: string) => void;
}) {
  return (
    <FormField
      control={control}
      name="photoUrls"
      render={({ field }) => (
        <FormItem>
          <PhotoUpload
            itemId={menuId}
            itemType="menus"
            onPhotoUploaded={onPhotoUploaded}
            existingPhotos={field.value}
            onPhotoRemoved={onPhotoRemoved}
            multiple
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}