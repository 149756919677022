import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

interface StockImage {
  id: string;
  url: string;
  description: string;
}

const STOCK_IMAGES: StockImage[] = [
  { id: 'photo-1649972904349-6e44c42644a7', url: '/placeholder.svg', description: 'Woman with laptop' },
  { id: 'photo-1488590528505-98d2b5aba04b', url: '/placeholder.svg', description: 'Gray laptop' },
  { id: 'photo-1518770660439-4636190af475', url: '/placeholder.svg', description: 'Circuit board' },
  { id: 'photo-1461749280684-dccba630e2f6', url: '/placeholder.svg', description: 'Programming monitor' },
  { id: 'photo-1486312338219-ce68d2c6f44d', url: '/placeholder.svg', description: 'Person using MacBook' },
];

interface StockImageDialogProps {
  onImageSelect: (imageUrl: string) => Promise<void>;
  disabled?: boolean;
}

export function StockImageDialog({ onImageSelect, disabled }: StockImageDialogProps) {
  const [showDialog, setShowDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const filteredImages = STOCK_IMAGES.filter(img => 
    img.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogTrigger asChild>
        <Button variant="outline" type="button" disabled={disabled}>
          <Search className="w-4 h-4 mr-2" />
          {t('forms.buttons.stockImages')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('forms.titles.stockImages')}</DialogTitle>
        </DialogHeader>
        <Input
          placeholder={t('forms.placeholders.searchStockImages')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {filteredImages.map((img) => (
            <div
              key={img.id}
              className="cursor-pointer hover:opacity-80"
              onClick={async () => {
                await onImageSelect(img.url);
                setShowDialog(false);
              }}
            >
              <img
                src={img.url}
                alt={img.description}
                className="w-full h-32 object-cover rounded-lg"
              />
              <p className="text-sm mt-1">{img.description}</p>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}