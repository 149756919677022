export const it = {
  common: {
    loading: "Caricamento...",
    error: "Errore",
    delete: "Elimina",
    edit: "Modifica",
    view: "Visualizza",
    create: "Crea",
    cancel: "Annulla",
    save: "Salva",
    back: "Indietro",
    required: "Campo obbligatorio",
    optional: "Opzionale",
    submit: "Invia",
    confirmDelete: "Sei sicuro di voler eliminare questo?",
  },
  forms: {
    validation: {
      required: "Questo campo è obbligatorio",
      invalidPrice: "Formato prezzo non valido",
      invalidDate: "Formato data non valido",
      invalidTime: "Formato ora non valido",
    },
    labels: {
      title: "Titolo",
      description: "Descrizione",
      price: "Prezzo",
      photo: "Foto",
      category: "Categoria",
      categories: "Categorie",
      allergens: "Allergeni",
      variations: "Variazioni",
      options: "Opzioni",
      additions: "Aggiunte",
      startDate: "Data inizio",
      endDate: "Data fine",
      startTime: "Ora inizio",
      endTime: "Ora fine",
      weekDays: "Giorni della settimana",
    },
    tooltips: {
      title: "Inserisci un titolo descrittivo",
      description: "Fornisci informazioni dettagliate",
      price: "Inserisci il prezzo in euro (es. 12,90)",
      photo: "Carica una foto",
      category: "Seleziona le categorie pertinenti",
      allergens: "Seleziona tutti gli allergeni applicabili",
      variations: "Aggiungi diverse versioni con i propri prezzi",
      options: "Aggiungi opzioni per questa variazione",
      additions: "Aggiungi extra opzionali",
      validity: "Imposta quando questo è valido",
    },
    placeholders: {
      title: "Inserisci titolo",
      description: "Inserisci descrizione",
      price: "0,00",
      search: "Cerca...",
    },
  },
  menus: {
    title: "Menu",
    description: "Collezioni di cibi organizzati in menu. Ogni menu può contenere più articoli e categorie.",
    create: "Crea menu",
    edit: "Modifica menu",
    delete: "Elimina menu",
    deleteConfirm: "Sei sicuro di voler eliminare questo menu? Questa azione non può essere annullata.",
    items: "articoli",
    updated: "Menu aggiornato",
    created: "Menu creato",
    updateSuccess: "Il tuo menu è stato aggiornato con successo.",
    createSuccess: "Il tuo menu è stato creato con successo.",
    loadError: "Impossibile caricare i dati del menu. Riprova.",
    updateError: "Impossibile aggiornare il menu. Riprova.",
    createError: "Impossibile creare il menu. Riprova.",
  },
  categories: {
    title: "Categorie",
    description: "Le categorie aiutano a organizzare gli elementi del menu in gruppi logici per una navigazione e gestione più facile.",
    create: "Crea categoria",
    edit: "Modifica categoria",
    delete: "Elimina categoria",
    deleteConfirm: "Sei sicuro di voler eliminare questa categoria? Questa azione non può essere annullata.",
    additions: "aggiunte",
    updated: "Categoria aggiornata",
    created: "Categoria creata",
    updateSuccess: "La categoria è stata aggiornata con successo.",
    createSuccess: "La categoria è stata creata con successo.",
    loadError: "Impossibile caricare i dati della categoria. Riprova.",
  },
  foodItems: {
    title: "Articoli",
    description: "Articoli individuali che possono essere aggiunti ai menu e alle promozioni. Ogni articolo include dettagli come prezzo, descrizione e categoria.",
    create: "Crea articolo",
    edit: "Modifica articolo",
    delete: "Elimina articolo",
    deleteConfirm: "Sei sicuro di voler eliminare questo articolo? Questa azione non può essere annullata.",
    variations: "variazioni",
    updated: "Articolo aggiornato",
    created: "Articolo creato",
    updateSuccess: "L'articolo è stato aggiornato con successo.",
    createSuccess: "L'articolo è stato creato con successo.",
    loadError: "Impossibile caricare i dati dell'articolo. Riprova.",
  },
  promotions: {
    title: "Promozioni",
    description: "Le promozioni definiscono offerte a tempo limitato per articoli ordinati insieme. Le offerte possono essere a prezzo fisso o in percentuale di sconto.",
    create: "Crea promozione",
    edit: "Modifica promozione",
    delete: "Elimina promozione",
    deleteConfirm: "Sei sicuro di voler eliminare questa promozione? Questa azione non può essere annullata.",
    status: {
      active: "Attiva",
      scheduled: "Programmata"
    },
    updated: "Promozione aggiornata",
    created: "Promozione creata",
    updateSuccess: "La promozione è stata aggiornata con successo.",
    createSuccess: "La promozione è stata creata con successo.",
    loadError: "Impossibile caricare i dati della promozione. Riprova.",
  },
  dashboard: {
    title: "Dashboard",
    description: "Panoramica delle metriche chiave e dell'attività recente del tuo ristorante.",
    welcome: "Benvenuto nel Portale Staff Gasto",
  }
};
