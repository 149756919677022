import { CategoryFormValues, FoodItemFormValues } from "@/types";
import { categoriesClient } from "./mockClients/categories";
import { foodItemsClient } from "./mockClients/foodItems";
import { menusClient } from "./mockClients/menus";
import { promotionsClient } from "./mockClients/promotions";
import { statsClient } from "./mockClients/stats";

export class MockStaffClient {
  async listFoodItems() {
    return foodItemsClient.list();
  }

  async createFoodItem(foodItem: FoodItemFormValues) {
    return foodItemsClient.create(foodItem);
  }

  async updateFoodItem(id: string, foodItem: FoodItemFormValues) {
    return foodItemsClient.update(id, foodItem);
  }

  async deleteFoodItem(id: string) {
    return foodItemsClient.delete(id);
  }

  async getFoodItem(id: string) {
    return foodItemsClient.get(id);
  }

  async listCategories() {
    return categoriesClient.list();
  }

  async createCategory(category: CategoryFormValues) {
    return categoriesClient.create(category);
  }

  async updateCategory(id: string, category: CategoryFormValues) {
    return categoriesClient.update(id, category);
  }

  async deleteCategory(id: string) {
    return categoriesClient.delete(id);
  }

  async getCategory(id: string) {
    return categoriesClient.get(id);
  }

  async listMenus() {
    return menusClient.list();
  }

  async createMenu(menu: any) {
    return menusClient.create(menu);
  }

  async updateMenu(id: string, menu: any) {
    return menusClient.update(id, menu);
  }

  async deleteMenu(id: string) {
    return menusClient.delete(id);
  }

  async getMenu(id: string) {
    return menusClient.get(id);
  }

  async listPromotions() {
    return promotionsClient.list();
  }

  async createPromotion(promotion: any) {
    return promotionsClient.create(promotion);
  }

  async updatePromotion(id: string, promotion: any) {
    return promotionsClient.update(id, promotion);
  }

  async deletePromotion(id: string) {
    return promotionsClient.delete(id);
  }

  async getPromotion(id: string) {
    return promotionsClient.get(id);
  }

  async getStats() {
    return statsClient.getStats();
  }
}

export const mockStaffClient = new MockStaffClient();