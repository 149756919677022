import { LocalizedTitleAndDescription } from "@/types";

export const mockCategories = [
  {
    id: "1",
    textualData: [
      {
        language: "EN" as const,
        title: "Pizza",
        description: "Traditional Italian pizzas"
      }
    ] as LocalizedTitleAndDescription[],
    photoUrl: null,
    additions: [
      {
        textualData: [
          {
            language: "EN" as const,
            title: "Extra cheese",
            description: "Add more mozzarella cheese"
          }
        ] as LocalizedTitleAndDescription[],
        price: {
          amount: 200,
          currency: "EUR" as const
        }
      }
    ]
  },
  {
    id: "2",
    textualData: [
      {
        language: "EN" as const,
        title: "Pasta",
        description: "Fresh homemade pasta"
      }
    ] as LocalizedTitleAndDescription[],
    photoUrl: null,
    additions: []
  }
];