import { Sidebar, SidebarContent, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@/components/ui/sidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { LayoutDashboard, Tag, Pizza, MenuSquare, Ticket, LogOut, Languages } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/auth/mockAuthProvider";
import { useLanguage } from "@/contexts/LanguageContext";
import { useTranslation } from "@/hooks/useTranslation";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const SUPPORTED_LANGUAGES = [
  { code: "EN", name: "English" },
  { code: "DE", name: "German" },
  { code: "FR", name: "French" },
  { code: "IT", name: "Italian" },
] as const;

export default function StaffLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { uiLanguage, setUiLanguage } = useLanguage();
  const { t } = useTranslation();
  
  const navigation = [
    { name: t('dashboard.title'), href: "/", icon: LayoutDashboard },
    { name: t('categories.title'), href: "/categories", icon: Tag },
    { name: t('foodItems.title'), href: "/food-items", icon: Pizza },
    { name: t('menus.title'), href: "/menus", icon: MenuSquare },
    { name: t('promotions.title'), href: "/promotions", icon: Ticket },
  ];

  const handleLogout = async () => {
    try {
      // Mock logout functionality
      await new Promise(resolve => setTimeout(resolve, 500));
      navigate('/auth/login');
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: t('common.error'),
        description: "Failed to sign out. Please try again.",
      });
    }
  };

  const handleLanguageChange = (newLang: string) => {
    console.log('Changing language to:', newLang);
    setUiLanguage(newLang as "EN" | "DE" | "FR" | "IT");
  };

  return (
    <SidebarProvider>
      <div className="min-h-screen flex w-full">
        <Sidebar>
          <SidebarContent>
            <div className="px-6 py-4">
              <h1 className="text-lg font-bold text-foreground">{t('dashboard.welcome')}</h1>
            </div>
            <SidebarGroup>
              <SidebarGroupLabel>Navigation</SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {navigation.map((item) => (
                    <SidebarMenuItem key={item.name}>
                      <SidebarMenuButton asChild>
                        <Link
                          to={item.href}
                          className={`flex items-center gap-3 ${
                            location.pathname === item.href
                              ? "text-primary bg-primary/10"
                              : "text-muted-foreground hover:text-foreground"
                          }`}
                        >
                          <item.icon className="h-4 w-4" />
                          <span>{item.name}</span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
            <div className="mt-auto p-4 space-y-4">
              <div className="flex items-center gap-2 px-2">
                <Languages className="h-4 w-4 text-muted-foreground" />
                <Select value={uiLanguage} onValueChange={handleLanguageChange}>
                  <SelectTrigger className="w-[140px]">
                    <SelectValue placeholder="Select language" />
                  </SelectTrigger>
                  <SelectContent>
                    {SUPPORTED_LANGUAGES.map((lang) => (
                      <SelectItem key={lang.code} value={lang.code}>
                        {lang.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button
                variant="outline"
                className="w-full justify-start"
                onClick={handleLogout}
              >
                <LogOut className="mr-2 h-4 w-4" />
                Sign out
              </Button>
            </div>
          </SidebarContent>
        </Sidebar>
        <main className="flex-1 overflow-auto">
          <div className="container py-6">
            {children}
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
}
