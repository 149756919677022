export const en = {
  common: {
    error: "Error",
    success: "Success",
    loading: "Loading...",
    save: "Save",
    cancel: "Cancel",
    delete: "Delete",
    edit: "Edit",
    create: "Create",
    view: "View",
    back: "Back",
    search: "Search",
    noResults: "No results found",
    required: "Required",
  },
  forms: {
    labels: {
      title: "Title",
      description: "Description",
      price: "Price",
      photo: "Photo",
      photos: "Photos",
      category: "Category",
      categories: "Categories",
      allergens: "Allergens",
      variations: "Variations",
      options: "Options",
      maxChoices: "Max Choices",
      startDate: "Start Date",
      endDate: "End Date",
      startTime: "Start Time",
      endTime: "End Time",
      weekDays: "Week Days",
      pricingType: "Pricing Type",
      fixedPrice: "Fixed Price",
      reduction: "Reduction",
      monday: "MO",
      tuesday: "TU",
      wednesday: "WE",
      thursday: "TH",
      friday: "FR",
      saturday: "SA",
      sunday: "SU",
    },
    tooltips: {
      title: "Enter a descriptive title",
      description: "Provide detailed information",
      price: "Set the price in euros",
      photo: "Upload a photo",
      category: "Select relevant categories",
      allergens: "Mark any allergens present",
      variations: "Add different versions or sizes",
      options: "Add available options",
      maxChoices: "Maximum number of choices allowed",
    },
    placeholders: {
      title: "Enter title",
      description: "Enter description",
      price: "Enter price",
      search: "Search...",
    },
    validation: {
      required: "This field is required",
      invalidPrice: "Invalid price format",
      invalidEmail: "Invalid email address",
      invalidPassword: "Invalid password",
    },
  },
  categories: {
    title: "Categories",
    create: "Create Category",
    edit: "Edit Category",
    delete: "Delete Category",
    view: "View Category",
    description: "Manage your food categories",
    deleteConfirm: "Are you sure you want to delete this category?",
    deleteSuccess: "Category deleted successfully",
    createSuccess: "Category created successfully",
    updateSuccess: "Category updated successfully",
  },
  foodItems: {
    title: "Food Items",
    create: "Create Food Item",
    edit: "Edit Food Item",
    delete: "Delete Food Item",
    view: "View Food Item",
    description: "Manage your food items",
    deleteConfirm: "Are you sure you want to delete this food item?",
    deleteSuccess: "Food item deleted successfully",
    createSuccess: "Food item created successfully",
    updateSuccess: "Food item updated successfully",
  },
  menus: {
    title: "Menus",
    create: "Create Menu",
    edit: "Edit Menu",
    delete: "Delete Menu",
    view: "View Menu",
    description: "Manage your menus",
    deleteConfirm: "Are you sure you want to delete this menu?",
    deleteSuccess: "Menu deleted successfully",
    createSuccess: "Menu created successfully",
    updateSuccess: "Menu updated successfully",
    items: "items",
    deleteError: "Failed to delete menu",
  },
  promotions: {
    title: "Promotions",
    create: "Create Promotion",
    edit: "Edit Promotion",
    delete: "Delete Promotion",
    view: "View Promotion",
    description: "Manage your promotions",
    deleteConfirm: "Are you sure you want to delete this promotion?",
    deleteSuccess: "Promotion deleted successfully",
    createSuccess: "Promotion created successfully",
    updateSuccess: "Promotion updated successfully",
  },
  dashboard: {
    title: "Dashboard",
    description: "Overview of your restaurant's key metrics and recent activity.",
    welcome: "Welcome to Gasto Staff Portal",
  }
};