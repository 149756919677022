import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Calendar, Edit, Info, Plus, Trash2, Utensils, Eye } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { useToast } from "@/components/ui/use-toast";
import { ValidityPeriodDisplay } from "@/components/shared/ValidityPeriodDisplay";
import { WeekDay } from "@/types";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export default function Index() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: promotionsData, isLoading, error } = useQuery({
    queryKey: ['promotions'],
    queryFn: () => mockStaffClient.listPromotions()
  });

  const { data: foodItems } = useQuery({
    queryKey: ['foodItems'],
    queryFn: () => mockStaffClient.listFoodItems()
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => mockStaffClient.deletePromotion(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['promotions'] });
      toast({
        title: "Promotion deleted",
        description: "The promotion has been deleted successfully.",
      });
    },
    onError: (error) => {
      console.error('Error deleting promotion:', error);
      toast({
        title: "Error",
        description: "Failed to delete the promotion. Please try again.",
        variant: "destructive",
      });
    }
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading promotions</div>;
  }

  const promotions = promotionsData || [];

  const getFoodItemsPreview = (foodItemIds: string[]) => {
    if (!foodItems) return "";
    const items = foodItems.filter(item => foodItemIds.includes(item.id));
    return items.map(item => item.textualData.find(t => t.language === "EN")?.title).join(", ");
  };

  const formatPrice = (promotion: typeof promotions[0]) => {
    if (promotion.fixedPrice) {
      return `Fixed price €${promotion.fixedPrice.toFixed(2)}`;
    }
    if (promotion.reduction) {
      return `${promotion.reduction}% off`;
    }
    return "";
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">Promotions</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <Info className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p>
                Promotions define a time limited offer for all items when ordered together. 
                Offer can either be a fixed price or a reduction. 
                Offer does not apply to chargeable additions.
              </p>
            </HoverCardContent>
          </HoverCard>
        </div>
        <Button onClick={() => navigate("/promotions/create")}>
          <Plus className="mr-2 h-4 w-4" />
          Create Promotion
        </Button>
      </div>

      <div className="grid gap-4">
        {promotions.map((promotion) => (
          <Card key={promotion.id}>
            <CardContent className="p-6">
              <div className="space-y-4">
                <div className="flex justify-between items-start">
                  <div className="space-y-2">
                    <div className="flex items-center gap-4">
                      <div className="h-24 w-24 rounded-lg overflow-hidden bg-muted flex items-center justify-center">
                        {promotion.photoUrl ? (
                          <img 
                            src={promotion.photoUrl} 
                            alt={`${promotion.foodItemIds.length} items`}
                            className="h-full w-full object-cover"
                          />
                        ) : (
                          <Utensils className="h-12 w-12 text-muted-foreground" />
                        )}
                      </div>
                      <div>
                        <h3 className="font-semibold text-lg">{`${promotion.foodItemIds.length} items`}</h3>
                        <p className="text-muted-foreground">{formatPrice(promotion)}</p>
                      </div>
                    </div>
                    <div className="p-3 bg-muted rounded-md">
                      {getFoodItemsPreview(promotion.foodItemIds)}
                    </div>
                    <div className="flex gap-3">
                      <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${
                        promotion.status === "active" 
                          ? "bg-green-100 text-green-700"
                          : "bg-orange-100 text-orange-700"
                      }`}>
                        {promotion.status === "active" ? "Active" : "Scheduled"}
                      </span>
                      <span className="inline-flex items-center px-3 py-1 rounded-full bg-blue-100 text-blue-700 text-sm">
                        <Calendar className="mr-2 h-4 w-4" />
                        <ValidityPeriodDisplay
                          weekDays={promotion.validityPeriod.weekDays as WeekDay[]}
                          startDay={promotion.validityPeriod.startDay}
                          endDay={promotion.validityPeriod.endDay}
                          startTime={promotion.validityPeriod.startTime}
                          endTime={promotion.validityPeriod.endTime}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <Button 
                      variant="outline" 
                      size="icon"
                      onClick={() => navigate(`/promotions/${promotion.id}`)}
                    >
                      <Eye className="h-4 w-4" />
                    </Button>
                    <Button 
                      variant="outline" 
                      size="icon"
                      onClick={() => navigate(`/promotions/${promotion.id}/edit`)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button variant="outline" size="icon" className="text-destructive">
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Delete Promotion</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete this promotion? This action cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => deleteMutation.mutate(promotion.id)}
                            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                          >
                            Delete
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
