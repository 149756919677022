import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { categoriesClient } from "@/api/mockClients/categories";
import { useToast } from "@/components/ui/use-toast";
import { Edit, Plus, Trash2, Utensils } from "lucide-react";
import { useLocalizedText } from "@/utils/textual";
import { useTranslation } from "@/hooks/useTranslation";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";

export default function CategoriesIndex() {
  const [categories, setCategories] = useState<any[]>([]);
  const getLocalizedText = useLocalizedText();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await categoriesClient.list();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast({
          title: t('common.error'),
          description: t('categories.loadError'),
          variant: "destructive",
        });
      }
    };

    fetchCategories();
  }, [toast, t]);

  const handleDelete = async (id: string) => {
    try {
      await categoriesClient.delete(id);
      toast({
        title: t('categories.deleted'),
        description: t('categories.deleteSuccess'),
      });
      setCategories(categories.filter(cat => cat.id !== id));
    } catch (error) {
      console.error('Error deleting category:', error);
      toast({
        title: t('common.error'),
        description: t('categories.deleteError'),
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">{t('categories.title')}</h1>
        <Button onClick={() => navigate("/categories/create")}>
          <Plus className="mr-2 h-4 w-4" />
          {t('categories.create')}
        </Button>
      </div>

      <div className="grid gap-4">
        {categories.map((category) => (
          <Card key={category.id} className="hover:bg-accent/50 transition-colors">
            <Link to={`/categories/${category.id}`}>
              <CardContent className="p-6">
                <div className="flex gap-6">
                  {category.photoUrl ? (
                    <img
                      src={category.photoUrl}
                      alt={getLocalizedText(category.textualData).title}
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-24 h-24 bg-muted rounded-lg flex items-center justify-center">
                      <Utensils className="h-8 w-8 text-muted-foreground" />
                    </div>
                  )}
                  <div className="flex-1 flex justify-between items-start">
                    <div>
                      <h3 className="font-semibold text-lg">
                        {getLocalizedText(category.textualData).title}
                      </h3>
                      <p className="text-muted-foreground">
                        {getLocalizedText(category.textualData).description}
                      </p>
                      {category.additions.length > 0 && (
                        <div className="mt-2">
                          <Badge variant="outline" className="text-xs">
                            {category.additions.length} {t('categories.additions')}
                          </Badge>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2" onClick={(e) => e.preventDefault()}>
                      <Button 
                        variant="outline" 
                        size="icon"
                        onClick={() => navigate(`/categories/${category.id}/edit`)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button variant="outline" size="icon" className="text-destructive">
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>{t('categories.delete')}</AlertDialogTitle>
                            <AlertDialogDescription>
                              {t('categories.deleteConfirm')}
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => handleDelete(category.id)}
                              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                            >
                              {t('common.delete')}
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Link>
          </Card>
        ))}
      </div>
    </div>
  );
}