import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { mockStaffClient } from "@/api/mockStaffClient";
import { useQuery } from "@tanstack/react-query";
import { Badge } from "@/components/ui/badge";
import { X, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Control } from "react-hook-form";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useLocalizedText } from "@/utils/textual";

interface CategoryFieldProps {
  control: Control<any>;
}

export function CategoryField({ control }: CategoryFieldProps) {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const getLocalizedText = useLocalizedText();

  const { data: categories = [] } = useQuery({
    queryKey: ["categories"],
    queryFn: () => mockStaffClient.listCategories(),
  });

  const filteredCategories = categories.filter(category => 
    getLocalizedText(category.textualData).title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <FormField
      control={control}
      name="categoryIds"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex items-center gap-2">
            Categories
            <InfoTooltip content="Select categories this food item belongs to" />
          </FormLabel>

          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button
                type="button"
                variant="outline"
                className="w-full justify-start"
              >
                <Plus className="mr-2 h-4 w-4" />
                {field.value?.length 
                  ? `${field.value.length} categories selected`
                  : "Add categories"}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Select Categories</DialogTitle>
              </DialogHeader>
              
              <div className="space-y-4">
                <Input
                  placeholder="Search categories..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="mb-4"
                />
                
                <ScrollArea className="h-[300px] pr-4">
                  <div className="space-y-2">
                    {filteredCategories.map((category) => (
                      <div
                        key={category.id}
                        className="flex items-center space-x-2 rounded-md border p-2 cursor-pointer hover:bg-accent"
                        onClick={() => {
                          const currentValues = field.value || [];
                          const newValue = currentValues.includes(category.id)
                            ? currentValues.filter((id: string) => id !== category.id)
                            : [...currentValues, category.id];
                          field.onChange(newValue);
                        }}
                      >
                        <div
                          className={`h-4 w-4 border rounded-sm ${
                            field.value?.includes(category.id)
                              ? "bg-primary border-primary"
                              : "border-input"
                          }`}
                        >
                          {field.value?.includes(category.id) && (
                            <X className="h-4 w-4 text-primary-foreground" />
                          )}
                        </div>
                        <span>{getLocalizedText(category.textualData).title}</span>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </div>
            </DialogContent>
          </Dialog>

          <div className="flex flex-wrap gap-2 mt-2">
            {field.value?.map((categoryId: string) => {
              const category = categories.find((c) => c.id === categoryId);
              if (!category) return null;
              return (
                <Badge
                  key={categoryId}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {getLocalizedText(category.textualData).title}
                  <X
                    className="h-3 w-3 cursor-pointer"
                    onClick={() => {
                      field.onChange(
                        field.value.filter((id: string) => id !== categoryId)
                      );
                    }}
                  />
                </Badge>
              );
            })}
          </div>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}