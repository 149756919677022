import { FormField, FormItem } from "@/components/ui/form";
import { Control } from "react-hook-form";
import { MenuFormValues } from "../MenuForm";
import { MultilingualInput } from "@/components/shared/MultilingualInput";

export function BasicInfoFields({ control }: { control: Control<MenuFormValues> }) {
  return (
    <FormItem>
      <FormField
        control={control}
        name="textualData"
        render={({ field }) => (
          <MultilingualInput
            control={control}
            fieldName="textualData"
            label="Menu Information"
            tooltip="The name and description of your menu as it will appear to customers"
            placeholderTitle="Lunch Menu"
            placeholderDescription="Available Monday to Friday, 11:00-15:00"
            multiline={true}
          />
        )}
      />
    </FormItem>
  );
}