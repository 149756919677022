import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { getUploadUrl, uploadFile } from "@/utils/fileUpload";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { useTranslation } from "@/hooks/useTranslation";
import { StockImageDialog } from "./photo-upload/StockImageDialog";
import { AIGenerationDialog } from "./photo-upload/AIGenerationDialog";
import { PhotoDisplay } from "./photo-upload/PhotoDisplay";
import { DropZone } from "./photo-upload/DropZone";

interface PhotoUploadProps {
  itemId?: string;
  itemType: 'food-items' | 'categories' | 'menus' | 'promotions';
  onPhotoUploaded: (photoUrl: string) => void;
  existingPhotos?: string[];
  onPhotoRemoved?: (photoUrl: string) => void;
  multiple?: boolean;
}

export function PhotoUpload({ 
  itemId, 
  itemType, 
  onPhotoUploaded, 
  existingPhotos = [], 
  onPhotoRemoved,
  multiple = false,
}: PhotoUploadProps) {
  const [isUploading, setIsUploading] = useState(false);
  const { toast } = useToast();
  const { t } = useTranslation();

  const handleFiles = async (files: File[]) => {
    setIsUploading(true);
    try {
      for (const file of files) {
        if (!file.type.startsWith('image/')) {
          toast({
            title: "Invalid file type",
            description: t('forms.errors.imageOnly'),
            variant: "destructive",
          });
          continue;
        }

        const { url, method } = await getUploadUrl(itemId!, itemType);
        await uploadFile(url, file, method);
        
        toast({
          title: t('forms.success.photoUploaded'),
          description: t('forms.success.photoUploadedDesc'),
        });
        
        onPhotoUploaded(url);

        if (!multiple) break;
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast({
        title: t('forms.errors.uploadFailed'),
        description: t('forms.errors.tryAgain'),
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleStockImageSelect = async (imageUrl: string) => {
    if (!itemId) return;
    
    try {
      setIsUploading(true);
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'stock-image.jpg', { type: 'image/jpeg' });
      await handleFiles([file]);
    } catch (error) {
      console.error('Stock image selection error:', error);
      toast({
        title: t('forms.errors.stockImageFailed'),
        description: t('forms.errors.tryAgain'),
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const isDisabled = !itemId || isUploading;

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <label className="text-sm font-medium">
          {t('forms.labels.photo')}
          <InfoTooltip content={t('forms.tooltips.singlePhotoUpload')} />
        </label>
      </div>

      <div className="flex gap-4 mb-4">
        <StockImageDialog 
          onImageSelect={handleStockImageSelect}
          disabled={isDisabled}
        />
        <AIGenerationDialog 
          onImageGenerated={handleStockImageSelect}
          disabled={isDisabled}
        />
      </div>

      <DropZone
        onFilesSelected={handleFiles}
        disabled={isDisabled}
        multiple={multiple}
        itemId={itemId}
      />

      <PhotoDisplay
        photos={existingPhotos}
        onPhotoRemoved={onPhotoRemoved || (() => {})}
      />
    </div>
  );
}