import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Calendar, ChevronLeft, Info, Utensils } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Badge } from "@/components/ui/badge";
import { ValidityPeriodDisplay } from "@/components/shared/ValidityPeriodDisplay";

export default function ViewMenu() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: menu, isLoading } = useQuery({
    queryKey: ['menus', id],
    queryFn: () => mockStaffClient.getMenu(id!),
  });

  const { data: foodItems } = useQuery({
    queryKey: ['foodItems'],
    queryFn: () => mockStaffClient.listFoodItems(),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!menu) {
    return <div>Menu not found</div>;
  }

  const getFoodItemNames = () => {
    return menu.foodItemIds
      .map(itemId => foodItems?.find(item => item.id === itemId)?.textualData[0].title)
      .filter(Boolean);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <Button variant="outline" onClick={() => navigate("/menus")}>
          <ChevronLeft className="h-4 w-4 mr-2" />
          Back to Menus
        </Button>
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">{menu.textualData[0].title}</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <Info className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p>Detailed view of the menu including its validity periods and included items.</p>
            </HoverCardContent>
          </HoverCard>
        </div>
      </div>

      <Card>
        <CardContent className="p-6 space-y-6">
          <div className="flex gap-6">
            {menu.photoUrl ? (
              <img
                src={menu.photoUrl}
                alt={menu.textualData[0].title}
                className="w-48 h-48 object-cover rounded-lg"
              />
            ) : (
              <div className="w-48 h-48 bg-gray-100 rounded-lg flex items-center justify-center">
                <Utensils className="w-12 h-12 text-gray-400" />
              </div>
            )}
            <div className="space-y-4 flex-1">
              <div>
                <h2 className="text-xl font-semibold">{menu.textualData[0].title}</h2>
                <p className="text-muted-foreground mt-1">{menu.textualData[0].description}</p>
              </div>
              {menu.validityPeriods?.map((period: any, index: number) => (
                <Badge key={index} variant="outline">
                  <Calendar className="w-4 h-4 mr-2" />
                  <ValidityPeriodDisplay
                    weekDays={period.weekDays}
                    startDay={period.startDay}
                    endDay={period.endDay}
                    startTime={period.startTime}
                    endTime={period.endTime}
                  />
                </Badge>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-3">Menu Items</h3>
            <div className="grid gap-3">
              {getFoodItemNames().map((itemName, index) => (
                <div key={index} className="p-4 border rounded-lg">
                  <h4 className="font-medium">{itemName}</h4>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
