import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ChevronLeft, Info, Plus, Utensils } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Badge } from "@/components/ui/badge";
import { useLocalizedText } from "@/utils/textual";

export default function ViewCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const getLocalizedText = useLocalizedText();

  const { data: category, isLoading } = useQuery({
    queryKey: ['categories', id],
    queryFn: () => mockStaffClient.getCategory(id!),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!category) {
    return <div>Category not found</div>;
  }

  const { title, description } = getLocalizedText(category.textualData);

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <Button variant="outline" onClick={() => navigate("/categories")}>
          <ChevronLeft className="h-4 w-4 mr-2" />
          Back to Categories
        </Button>
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">{title}</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <Info className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p>Detailed view of the category including its description and additions.</p>
            </HoverCardContent>
          </HoverCard>
        </div>
      </div>

      <Card>
        <CardContent className="p-6 space-y-6">
          <div className="flex gap-6">
            {category.photoUrl ? (
              <img
                src={category.photoUrl}
                alt={title}
                className="w-48 h-48 object-cover rounded-lg"
              />
            ) : (
              <div className="w-48 h-48 bg-gray-100 rounded-lg flex items-center justify-center">
                <Utensils className="w-12 h-12 text-gray-400" />
              </div>
            )}
            <div className="space-y-4 flex-1">
              <div>
                <h2 className="text-xl font-semibold">{title}</h2>
                <p className="text-muted-foreground mt-1">{description}</p>
              </div>
            </div>
          </div>

          {category.additions && category.additions.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-3">Additions</h3>
              <div className="grid gap-3">
                {category.additions.map((addition, index) => {
                  const { title: additionTitle, description: additionDescription } = getLocalizedText(addition.textualData);
                  return (
                    <div key={index} className="p-4 border rounded-lg">
                      <div className="flex justify-between items-center">
                        <div>
                          <h4 className="font-medium">{additionTitle}</h4>
                          <p className="text-sm text-muted-foreground">
                            {additionDescription}
                          </p>
                        </div>
                        <Badge>€{(addition.price.amount / 100).toFixed(2)}</Badge>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
