import { X } from "lucide-react";

interface PhotoDisplayProps {
  photos: string[];
  onPhotoRemoved: (photoUrl: string) => void;
}

export function PhotoDisplay({ photos, onPhotoRemoved }: PhotoDisplayProps) {
  if (photos.length === 0) return null;

  return (
    <div className="mt-4">
      {photos.map((photo, index) => (
        <div key={index} className="relative group">
          <img
            src={photo}
            alt={`Upload ${index + 1}`}
            className="w-full h-32 object-cover rounded-lg"
          />
          <button
            onClick={() => onPhotoRemoved(photo)}
            className="absolute top-2 right-2 p-1 bg-destructive rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <X className="h-4 w-4 text-white" />
          </button>
        </div>
      ))}
    </div>
  );
}