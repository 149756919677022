import { Button } from "@/components/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Control } from "react-hook-form";
import { PromotionFormValues } from "../PromotionForm";
import { InfoTooltip } from "./InfoTooltip";
import { useTranslation } from "@/hooks/useTranslation";

const weekDays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"] as const;
const weekDayTranslations = {
  MO: 'forms.labels.monday',
  TU: 'forms.labels.tuesday',
  WE: 'forms.labels.wednesday',
  TH: 'forms.labels.thursday',
  FR: 'forms.labels.friday',
  SA: 'forms.labels.saturday',
  SU: 'forms.labels.sunday',
};

export function WeekDaysField({ control }: { control: Control<PromotionFormValues> }) {
  const { t } = useTranslation();

  return (
    <FormField
      control={control}
      name="weekDays"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex items-center gap-2">
            {t('forms.labels.weekDays')}
            <InfoTooltip content={t('forms.tooltips.validity')} />
          </FormLabel>
          <FormControl>
            <div className="flex flex-wrap gap-2">
              {weekDays.map((day) => (
                <Button
                  key={day}
                  type="button"
                  variant={field.value?.includes(day) ? "default" : "outline"}
                  onClick={() => {
                    const newValue = field.value || [];
                    const updatedValue = newValue.includes(day)
                      ? newValue.filter((d) => d !== day)
                      : [...newValue, day];
                    field.onChange(updatedValue);
                  }}
                >
                  {t(weekDayTranslations[day])}
                </Button>
              ))}
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}