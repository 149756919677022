import { en } from './en';
import { de } from './de';
import { fr } from './fr';
import { it } from './it';

export const translations = {
  EN: en,
  DE: de,
  FR: fr,
  IT: it,
} as const;

export type TranslationKey = keyof typeof translations.EN;