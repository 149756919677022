export const mockStats = {
  foodItems: {
    today: [
      { id: "1", title: "Spaghetti Carbonara", orders: 42 },
      { id: "2", title: "Tiramisu", orders: 28 }
    ],
    yearly: [
      { id: "1", title: "Spaghetti Carbonara", orders: 15420 },
      { id: "2", title: "Tiramisu", orders: 10234 }
    ]
  },
  categories: {
    today: [
      { id: "1", title: "Main Dishes", orders: 156 },
      { id: "2", title: "Desserts", orders: 89 }
    ],
    yearly: [
      { id: "1", title: "Main Dishes", orders: 52340 },
      { id: "2", title: "Desserts", orders: 34120 }
    ]
  }
};