import { Promotion } from "@/types";
import { mockPromotions as initialPromotions } from "../mockData/promotions";

let promotions = [...initialPromotions];

export const promotionsClient = {
  list: async (): Promise<Promotion[]> => {
    console.log('Listing promotions:', promotions);
    return promotions;
  },

  create: async (promotion: Partial<Promotion>): Promise<Promotion> => {
    console.log('Creating promotion with data:', promotion);
    const newPromotion: Promotion = {
      id: Math.random().toString(),
      status: "scheduled",
      dateRange: "Custom date range",
      validityPeriod: {
        ...promotion.validityPeriod,
        zoneId: "CET"
      },
      foodItemIds: promotion.foodItemIds || [],
      fixedPrice: promotion.fixedPrice,
      reduction: promotion.reduction,
      photoUrl: promotion.photoUrl
    };
    
    promotions.push(newPromotion);
    console.log('Created promotion:', newPromotion);
    return newPromotion;
  },

  get: async (id: string): Promise<Promotion | undefined> => {
    console.log('Getting promotion:', id);
    return promotions.find(promo => promo.id === id);
  },

  update: async (id: string, promotion: Partial<Promotion>): Promise<Promotion> => {
    console.log('Updating promotion:', id, promotion);
    const index = promotions.findIndex(promo => promo.id === id);
    if (index === -1) throw new Error('Promotion not found');
    
    const updatedPromotion: Promotion = {
      id,
      status: "scheduled",
      dateRange: "Custom date range",
      validityPeriod: {
        ...promotion.validityPeriod,
        zoneId: "CET"
      },
      foodItemIds: promotion.foodItemIds || [],
      fixedPrice: promotion.fixedPrice,
      reduction: promotion.reduction,
      photoUrl: promotion.photoUrl
    };
    
    promotions[index] = updatedPromotion;
    return updatedPromotion;
  },

  delete: async (id: string): Promise<boolean> => {
    console.log('Deleting promotion:', id);
    promotions = promotions.filter(promo => promo.id !== id);
    return true;
  }
};