import { format } from "date-fns";
import { WeekDay } from "@/types";

interface ValidityPeriodProps {
  weekDays?: WeekDay[];
  startDay?: string;
  endDay?: string;
  startTime?: string;
  endTime?: string;
}

export function ValidityPeriodDisplay({ weekDays, startDay, endDay, startTime, endTime }: ValidityPeriodProps) {
  const formatWeekDays = (days: WeekDay[]) => {
    if (!days || days.length === 0) return null;

    // Sort days according to calendar order (MO to SU)
    const dayOrder: { [key in WeekDay]: number } = {
      MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6, SU: 7
    };
    const sortedDays = [...days].sort((a, b) => dayOrder[a] - dayOrder[b]);

    // Find sequential ranges
    const ranges: WeekDay[][] = [];
    let currentRange: WeekDay[] = [sortedDays[0]];

    for (let i = 1; i < sortedDays.length; i++) {
      const currentDay = sortedDays[i];
      const prevDay = sortedDays[i - 1];
      
      if (dayOrder[currentDay] - dayOrder[prevDay] === 1) {
        currentRange.push(currentDay);
      } else {
        ranges.push([...currentRange]);
        currentRange = [currentDay];
      }
    }
    ranges.push(currentRange);

    // Format ranges
    return ranges.map(range => {
      if (range.length === 1) return range[0];
      return `${range[0]}-${range[range.length - 1]}`;
    }).join(", ");
  };

  const formatDate = (dateStr?: string) => {
    if (!dateStr) return null;
    
    // Handle the --MM-DD format
    const matches = dateStr.match(/^--(\d{2})-(\d{2})$/);
    if (!matches) return null;
    
    const [, month, day] = matches;
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, parseInt(month, 10) - 1, parseInt(day, 10));
    
    return format(date, "MMM d");
  };

  const formatTime = (timeStr?: string) => {
    if (!timeStr) return null;
    // Remove seconds from time string if present
    return timeStr.replace(/:\d{2}$/, '');
  };

  const parts: string[] = [];

  // Add week days if present
  const formattedWeekDays = weekDays && formatWeekDays(weekDays);
  if (formattedWeekDays) {
    parts.push(formattedWeekDays);
  }

  // Add date range if present
  const formattedStartDate = formatDate(startDay);
  const formattedEndDate = formatDate(endDay);
  if (formattedStartDate && formattedEndDate) {
    parts.push(`${formattedStartDate}-${formattedEndDate}`);
  }

  // Add time range if present
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);
  if (formattedStartTime && formattedEndTime) {
    parts.push(`${formattedStartTime}-${formattedEndTime}`);
  }

  return (
    <span>
      {parts.length > 0 ? parts.join(" • ") : "Always valid"}
    </span>
  );
}