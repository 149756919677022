import { Button } from "@/components/ui/button";
import { Form, FormField } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { PhotoUpload } from "@/components/shared/PhotoUpload";
import { BasicInfoFields } from "./form-sections/BasicInfoFields";
import { CategoryField } from "./form-sections/CategoryField";
import { AllergensField } from "./form-sections/AllergensField";
import { VariationsField } from "./form-sections/VariationsField";
import { mockStaffClient } from "@/api/mockStaffClient";
import { FoodItemFormValues } from "@/types";

const formSchema = z.object({
  id: z.string().optional(),
  textualData: z.array(z.object({
    language: z.enum(["EN", "DE", "FR", "IT"]),
    title: z.string().min(1, "Title is required"),
    description: z.string().optional()
  })),
  price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
  categoryIds: z.array(z.string()).min(1, "At least one category is required"),
  allergens: z.array(z.string()).default([]),
  photoUrls: z.array(z.string()).default([]),
  variations: z.array(z.object({
    title_en: z.string().min(1, "English title is required"),
    title_de: z.string().optional(),
    title_fr: z.string().optional(),
    title_it: z.string().optional(),
    description_en: z.string().optional(),
    description_de: z.string().optional(),
    description_fr: z.string().optional(),
    description_it: z.string().optional(),
    price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
    options: z.array(z.object({
      title_en: z.string().min(1, "English title is required"),
      title_de: z.string().optional(),
      title_fr: z.string().optional(),
      title_it: z.string().optional(),
      description_en: z.string().optional(),
      description_de: z.string().optional(),
      description_fr: z.string().optional(),
      description_it: z.string().optional(),
      price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
    })).default([]),
    maxChoices: z.number().min(1).optional(),
  })).default([])
});

interface FoodItemFormProps {
  initialData?: FoodItemFormValues;
  isEditing?: boolean;
}

export function FoodItemForm({ initialData, isEditing = false }: FoodItemFormProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  
  const form = useForm<FoodItemFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData || {
      id: undefined,
      textualData: [],
      price: "",
      categoryIds: [],
      allergens: [],
      photoUrls: [],
      variations: [],
    },
  });

  async function onSubmit(values: FoodItemFormValues) {
    try {
      console.log("Food item form submitted with values:", values);

      if (isEditing && initialData?.id) {
        await mockStaffClient.updateFoodItem(initialData.id, values);
      } else {
        await mockStaffClient.createFoodItem(values);
      }

      toast({
        title: `Food item ${isEditing ? 'updated' : 'created'}`,
        description: `Your food item has been ${isEditing ? 'updated' : 'created'} successfully.`,
      });
      
      navigate("/food-items");
    } catch (error) {
      console.error('Error submitting food item:', error);
      toast({
        title: "Error",
        description: `Failed to ${isEditing ? 'update' : 'create'} food item. Please try again.`,
        variant: "destructive",
      });
    }
  }

  const handlePhotoUploaded = (url: string) => {
    const currentPhotos = form.getValues("photoUrls") || [];
    form.setValue("photoUrls", [...currentPhotos, url]);
  };

  const handlePhotoRemoved = (urlToRemove: string) => {
    const currentPhotos = form.getValues("photoUrls") || [];
    form.setValue("photoUrls", currentPhotos.filter(url => url !== urlToRemove));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <BasicInfoFields control={form.control} />
        <CategoryField control={form.control} />
        <AllergensField control={form.control} />
        <VariationsField control={form.control} />
        
        <FormField
          control={form.control}
          name="photoUrls"
          render={({ field }) => (
            <PhotoUpload
              itemId={initialData?.id}
              itemType="food-items"
              onPhotoUploaded={handlePhotoUploaded}
              existingPhotos={field.value}
              onPhotoRemoved={handlePhotoRemoved}
              multiple
            />
          )}
        />
        
        <div className="flex justify-end gap-4">
          <Button variant="outline" type="button" onClick={() => navigate("/food-items")}>
            Cancel
          </Button>
          <Button type="submit">{isEditing ? 'Update' : 'Create'} Food Item</Button>
        </div>
      </form>
    </Form>
  );
}
