import { Button } from "@/components/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Control, FieldValues, Path } from "react-hook-form";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { useState } from "react";
import { useLanguage } from "@/contexts/LanguageContext";
import { useTranslation } from "@/hooks/useTranslation";
import { LocalizedTitleAndDescription } from "@/types";
import { 
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const SUPPORTED_LANGUAGES = [
  { code: "EN", name: "English" },
  { code: "DE", name: "German" },
  { code: "FR", name: "French" },
  { code: "IT", name: "Italian" },
] as const;

type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number]["code"];

interface MultilingualInputProps<T extends FieldValues> {
  control: Control<T>;
  fieldName: string;
  label: string;
  tooltip?: string;
  placeholderTitle?: string;
  placeholderDescription?: string;
  required?: boolean;
  multiline?: boolean;
}

export function MultilingualInput<T extends FieldValues>({
  control,
  fieldName,
  label,
  tooltip,
  placeholderTitle,
  placeholderDescription,
  required = true,
  multiline = false,
}: MultilingualInputProps<T>) {
  const { defaultLanguage } = useLanguage();
  const { t } = useTranslation();
  const [selectedLanguages, setSelectedLanguages] = useState<SupportedLanguage[]>([defaultLanguage as SupportedLanguage]);

  const availableLanguages = SUPPORTED_LANGUAGES.filter(
    lang => !selectedLanguages.includes(lang.code)
  );

  const handleAddLanguage = (langCode: SupportedLanguage) => {
    setSelectedLanguages(prev => [...prev, langCode]);
  };

  const handleRemoveLanguage = (langCode: SupportedLanguage) => {
    if (langCode === defaultLanguage) return;
    setSelectedLanguages(prev => prev.filter(lang => lang !== langCode));
  };

  const renderLanguageFields = (langCode: SupportedLanguage) => {
    const languageName = SUPPORTED_LANGUAGES.find(l => l.code === langCode)?.name;

    return (
      <AccordionItem value={langCode} key={langCode} className="border rounded-lg mb-4">
        <FormField
          control={control}
          name={`${fieldName}` as Path<T>}
          render={({ field }) => {
            const currentData = (field.value || []) as LocalizedTitleAndDescription[];
            const langData = currentData.find(d => d?.language === langCode) || {
              language: langCode,
              title: "",
              description: "",
            };

            const updateTextualData = (
              updatedData: Partial<LocalizedTitleAndDescription>
            ) => {
              const newData = currentData.filter(d => d?.language !== langCode);
              newData.push({ ...langData, ...updatedData });
              field.onChange(newData);
            };

            return (
              <>
                <AccordionTrigger className="px-4 hover:no-underline">
                  <div className="flex flex-col items-start w-full text-left">
                    <span className="font-medium mb-1">
                      {languageName} {langCode === defaultLanguage && required && '*'}
                    </span>
                    <div className="text-sm text-muted-foreground" data-state-parent>
                      <div><span className="font-medium">Title:</span> {langData.title || '(empty)'}</div>
                      {multiline && (
                        <div><span className="font-medium">Description:</span> {langData.description || '(empty)'}</div>
                      )}
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-4">
                  <div className="space-y-4">
                    {langCode !== defaultLanguage && (
                      <div className="flex justify-end">
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => handleRemoveLanguage(langCode)}
                        >
                          {t('common.delete')}
                        </Button>
                      </div>
                    )}

                    <FormItem>
                      <FormLabel className="flex items-center gap-2">
                        {t('forms.labels.title')}
                        {tooltip && <InfoTooltip content={tooltip} />}
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={placeholderTitle || t('forms.placeholders.title')}
                          value={langData.title}
                          onChange={(e) =>
                            updateTextualData({ title: e.target.value })
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>

                    {multiline && (
                      <FormItem>
                        <FormLabel className="flex items-center gap-2">
                          {t('forms.labels.description')}
                          {tooltip && <InfoTooltip content={tooltip} />}
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder={placeholderDescription || t('forms.placeholders.description')}
                            value={langData.description || ""}
                            onChange={(e) =>
                              updateTextualData({ description: e.target.value })
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  </div>
                </AccordionContent>
              </>
            );
          }}
        />
      </AccordionItem>
    );
  };

  return (
    <div className="space-y-4">
      <style>
        {`
          [data-state="open"] [data-state-parent] {
            display: none;
          }
        `}
      </style>
      <Accordion type="single" collapsible className="w-full">
        {selectedLanguages.map(renderLanguageFields)}
      </Accordion>

      {availableLanguages.length > 0 && (
        <div className="flex gap-2">
          <Select onValueChange={(value: SupportedLanguage) => handleAddLanguage(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder={t('common.create')} />
            </SelectTrigger>
            <SelectContent>
              {availableLanguages.map((lang) => (
                <SelectItem key={lang.code} value={lang.code}>
                  {lang.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  );
}