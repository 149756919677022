export const fr = {
  common: {
    loading: "Chargement...",
    error: "Erreur",
    delete: "Supprimer",
    edit: "Modifier",
    view: "Voir",
    create: "Créer",
    cancel: "Annuler",
    save: "Enregistrer",
    back: "Retour",
    required: "Champ obligatoire",
    optional: "Optionnel",
    submit: "Soumettre",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer ceci ?",
  },
  forms: {
    validation: {
      required: "Ce champ est obligatoire",
      invalidPrice: "Format de prix invalide",
      invalidDate: "Format de date invalide",
      invalidTime: "Format d'heure invalide",
    },
    labels: {
      title: "Titre",
      description: "Description",
      price: "Prix",
      photo: "Photo",
      category: "Catégorie",
      categories: "Catégories",
      allergens: "Allergènes",
      variations: "Variations",
      options: "Options",
      additions: "Suppléments",
      startDate: "Date de début",
      endDate: "Date de fin",
      startTime: "Heure de début",
      endTime: "Heure de fin",
      weekDays: "Jours de la semaine",
    },
    tooltips: {
      title: "Entrez un titre descriptif",
      description: "Fournissez des informations détaillées",
      price: "Entrez le prix en euros (ex: 12,90)",
      photo: "Télécharger une photo",
      category: "Sélectionnez les catégories pertinentes",
      allergens: "Sélectionnez tous les allergènes applicables",
      variations: "Ajoutez différentes versions avec leurs propres prix",
      options: "Ajoutez des options pour cette variation",
      additions: "Ajoutez des extras optionnels",
      validity: "Définissez quand ceci est valide",
    },
    placeholders: {
      title: "Entrez le titre",
      description: "Entrez la description",
      price: "0,00",
      search: "Rechercher...",
    },
  },
  menus: {
    title: "Menus",
    description: "Collections d'articles alimentaires organisés en menus. Chaque menu peut contenir plusieurs articles et catégories.",
    create: "Créer un menu",
    edit: "Modifier le menu",
    delete: "Supprimer le menu",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce menu ? Cette action ne peut pas être annulée.",
    items: "articles",
    updated: "Menu mis à jour",
    created: "Menu créé",
    updateSuccess: "Votre menu a été mis à jour avec succès.",
    createSuccess: "Votre menu a été créé avec succès.",
    loadError: "Échec du chargement des données du menu. Veuillez réessayer.",
    updateError: "Échec de la mise à jour du menu. Veuillez réessayer.",
    createError: "Échec de la création du menu. Veuillez réessayer.",
  },
  categories: {
    title: "Catégories",
    description: "Les catégories aident à organiser les articles du menu en groupes logiques pour une navigation et une gestion plus faciles.",
    create: "Créer une catégorie",
    edit: "Modifier la catégorie",
    delete: "Supprimer la catégorie",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette catégorie ? Cette action ne peut pas être annulée.",
    additions: "suppléments",
    updated: "Catégorie mise à jour",
    created: "Catégorie créée",
    updateSuccess: "La catégorie a été mise à jour avec succès.",
    createSuccess: "La catégorie a été créée avec succès.",
    loadError: "Échec du chargement des données de la catégorie. Veuillez réessayer.",
  },
  foodItems: {
    title: "Articles",
    description: "Articles individuels qui peuvent être ajoutés aux menus et promotions. Chaque article comprend des détails comme le prix, la description et la catégorie.",
    create: "Créer un article",
    edit: "Modifier l'article",
    delete: "Supprimer l'article",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer cet article ? Cette action ne peut pas être annulée.",
    variations: "variations",
    updated: "Article mis à jour",
    created: "Article créé",
    updateSuccess: "L'article a été mis à jour avec succès.",
    createSuccess: "L'article a été créé avec succès.",
    loadError: "Échec du chargement des données de l'article. Veuillez réessayer.",
  },
  promotions: {
    title: "Promotions",
    description: "Les promotions définissent des offres limitées dans le temps pour les articles commandés ensemble. Les offres peuvent être à prix fixe ou en réduction pourcentage.",
    create: "Créer une promotion",
    edit: "Modifier la promotion",
    delete: "Supprimer la promotion",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette promotion ? Cette action ne peut pas être annulée.",
    status: {
      active: "Active",
      scheduled: "Planifiée"
    },
    updated: "Promotion mise à jour",
    created: "Promotion créée",
    updateSuccess: "La promotion a été mise à jour avec succès.",
    createSuccess: "La promotion a été créée avec succès.",
    loadError: "Échec du chargement des données de la promotion. Veuillez réessayer.",
  },
  dashboard: {
    title: "Tableau de bord",
    description: "Aperçu des indicateurs clés et de l'activité récente de votre restaurant.",
    welcome: "Bienvenue sur le Portail Staff Gasto",
  }
};
