import { Control } from "react-hook-form";
import { MultilingualInput } from "@/components/shared/MultilingualInput";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { FoodItemFormValues } from "@/types";

interface BasicInfoFieldsProps {
  control: Control<FoodItemFormValues>;
}

export function BasicInfoFields({ control }: BasicInfoFieldsProps) {
  return (
    <>
      <MultilingualInput
        control={control}
        fieldName="description"
        label="Description"
        tooltip="Detailed description of the food item"
        placeholderTitle="Pizza Margherita"
        placeholderDescription="Fresh tomatoes, mozzarella, and basil"
        multiline
      />

      <FormField
        control={control}
        name="price"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="flex items-center gap-2">
              Price (€) *
              <InfoTooltip content="Price in euros (e.g., 12.90)" />
            </FormLabel>
            <FormControl>
              <Input placeholder="12.90" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}