import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { MultilingualInput } from "@/components/shared/MultilingualInput";
import { Input } from "@/components/ui/input";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { useTranslation } from "@/hooks/useTranslation";
import { VariationFormValues } from "@/types";

const variationSchema = z.object({
  textualData: z.array(z.object({
    language: z.enum(["EN", "DE", "FR", "IT"]),
    title: z.string().min(1, "Title is required"),
    description: z.string().optional()
  })),
  price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
  options: z.array(z.object({
    textualData: z.array(z.object({
      language: z.enum(["EN", "DE", "FR", "IT"]),
      title: z.string().min(1, "Title is required"),
      description: z.string().optional()
    })),
    price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
  })).optional(),
  maxChoices: z.number().min(1).default(1),
});

interface VariationDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (values: VariationFormValues) => void;
  initialData?: VariationFormValues;
}

export function VariationDialog({ open, onOpenChange, onSubmit, initialData }: VariationDialogProps) {
  const { t } = useTranslation();
  const form = useForm<VariationFormValues>({
    resolver: zodResolver(variationSchema),
    defaultValues: initialData || {
      textualData: [],
      price: "",
      options: [],
      maxChoices: 1,
    },
  });

  const handleSubmit = (values: VariationFormValues) => {
    onSubmit(values);
    onOpenChange(false);
    form.reset();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>{t('forms.labels.variations')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            <MultilingualInput
              control={form.control}
              fieldName="textualData"
              label={t('forms.labels.title')}
              tooltip={t('forms.tooltips.title')}
              placeholderTitle={t('forms.placeholders.title')}
              multiline
            />

            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center gap-2">
                    {t('forms.labels.price')}
                    <InfoTooltip content={t('forms.tooltips.price')} />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder={t('forms.placeholders.price')} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4 pt-4">
              <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
                {t('common.cancel')}
              </Button>
              <Button type="submit">{t('common.submit')}</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
