import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Button } from "@/components/ui/button";
import { Plus, Pencil, Trash2, DollarSign, Tag, Layers, Utensils } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "@/hooks/useTranslation";
import { useLocalizedText } from "@/utils/textual";

export default function Index() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();
  const getLocalizedText = useLocalizedText();

  const { data: foodItems = [], refetch } = useQuery({
    queryKey: ['foodItems'],
    queryFn: () => mockStaffClient.listFoodItems(),
  });

  const { data: categories = [] } = useQuery({
    queryKey: ['categories'],
    queryFn: () => mockStaffClient.listCategories(),
  });

  const handleDelete = async (id: string) => {
    try {
      await mockStaffClient.deleteFoodItem(id);
      toast({
        title: t('common.success'),
        description: t('pages.foodItems.deleteSuccess'),
      });
      refetch();
    } catch (error) {
      toast({
        title: t('common.error'),
        description: t('pages.foodItems.deleteError'),
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">{t('pages.foodItems.title')}</h1>
        <Button onClick={() => navigate("/food-items/create")}>
          <Plus className="h-4 w-4" />
          {t('pages.foodItems.create')}
        </Button>
      </div>

      <div className="space-y-4">
        {foodItems.map((item) => {
          const { title, description } = getLocalizedText(item.textualData);
          const price = (item.price.amount / 100).toFixed(2);
          const itemCategories = categories.filter(cat => item.categoryIds.includes(cat.id));
          
          return (
            <div
              key={item.id}
              className="group relative border rounded-lg p-4 hover:shadow-md transition-shadow w-full"
            >
              <Link to={`/food-items/${item.id}`} className="block">
                <div className="flex gap-4">
                  {item.photoUrl ? (
                    <img
                      src={item.photoUrl}
                      alt={title}
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                      <Utensils className="w-8 h-8 text-gray-400" />
                    </div>
                  )}
                  <div className="flex-1 min-w-0">
                    <h2 className="text-lg font-semibold truncate">{title}</h2>
                    <p className="text-sm text-muted-foreground line-clamp-2">{description}</p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <Badge variant="secondary" className="flex items-center gap-1 bg-[#9b87f5] text-white">
                        <DollarSign className="h-3 w-3" />
                        €{price}
                      </Badge>
                      {itemCategories.length > 0 && (
                        <Badge variant="secondary" className="flex items-center gap-1 bg-[#7E69AB] text-white">
                          <Tag className="h-3 w-3" />
                          {itemCategories.length} {t('common.categories')}
                        </Badge>
                      )}
                      {item.variations.length > 0 && (
                        <Badge variant="secondary" className="flex items-center gap-1 bg-[#6E59A5] text-white">
                          <Layers className="h-3 w-3" />
                          {item.variations.length} {t('common.variations')}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
              <div className="absolute top-4 right-4 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/food-items/${item.id}/edit`);
                  }}
                >
                  <Pencil className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(item.id);
                  }}
                  className="text-destructive"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}