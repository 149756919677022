import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { mockStaffClient } from "@/api/mockStaffClient";
import { AdditionsSection } from "./form-sections/AdditionsSection";
import { BasicInfoFields } from "./form-sections/BasicInfoFields";
import { FormActions } from "./form-sections/FormActions";
import { CategoryFormValues } from "@/types";
import { useTranslation } from "@/hooks/useTranslation";

const formSchema = z.object({
  id: z.string().optional(),
  textualData: z.array(z.object({
    language: z.enum(["EN", "DE", "FR", "IT"]),
    title: z.string().min(1, "Title is required"),
    description: z.string().optional()
  })),
  photoUrl: z.string().optional(),
  additions: z.array(z.object({
    textualData: z.array(z.object({
      language: z.enum(["EN", "DE", "FR", "IT"]),
      title: z.string().min(1, "Title is required"),
      description: z.string().optional()
    })),
    price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
  })).default([]),
});

interface CategoryFormProps {
  initialData?: CategoryFormValues;
  isEditing?: boolean;
}

export function CategoryForm({ initialData, isEditing = false }: CategoryFormProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const form = useForm<CategoryFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData || {
      textualData: [],
      photoUrl: "",
      additions: [],
    },
  });

  async function onSubmit(values: CategoryFormValues) {
    try {
      console.log("Category form submitted with values:", values);
      
      if (isEditing && values.id) {
        await mockStaffClient.updateCategory(values.id, values);
        toast({
          title: t('categories.updated'),
          description: t('categories.updateSuccess'),
        });
      } else {
        await mockStaffClient.createCategory(values);
        toast({
          title: t('categories.created'),
          description: t('categories.createSuccess'),
        });
      }
      
      navigate("/categories");
    } catch (error) {
      console.error('Error submitting category:', error);
      toast({
        title: t('common.error'),
        description: t('categories.loadError'),
        variant: "destructive",
      });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <BasicInfoFields 
          control={form.control}
          initialData={initialData}
        />
        
        <AdditionsSection control={form.control} />
        
        <FormActions isEditing={isEditing} />
      </form>
    </Form>
  );
}