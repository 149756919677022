import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Control } from "react-hook-form";
import { PromotionFormValues } from "../PromotionForm";
import { InfoTooltip } from "./InfoTooltip";
import { useTranslation } from "@/hooks/useTranslation";

export function TimeFields({ control }: { control: Control<PromotionFormValues> }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-span-2">
        <h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
          {t('forms.labels.validity')}
          <InfoTooltip content={t('forms.tooltips.validity')} />
        </h3>
      </div>

      <FormField
        control={control}
        name="startDay"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="flex items-center gap-2">
              {t('forms.labels.startDate')}
              <InfoTooltip content={t('forms.tooltips.validity')} />
            </FormLabel>
            <FormControl>
              <Input type="date" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="endDay"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="flex items-center gap-2">
              {t('forms.labels.endDate')}
              <InfoTooltip content={t('forms.tooltips.validity')} />
            </FormLabel>
            <FormControl>
              <Input type="date" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="startTime"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('forms.labels.startTime')}</FormLabel>
            <FormControl>
              <Input type="time" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="endTime"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('forms.labels.endTime')}</FormLabel>
            <FormControl>
              <Input type="time" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}