import { mockMenus as initialMenus } from "../mockData/menus";

let menus = [...initialMenus];

export const menusClient = {
  list: async () => {
    console.log('Listing menus:', menus);
    return menus;
  },

  create: async (menu: any) => {
    console.log('Creating menu with data:', menu);
    const newMenu = {
      id: Math.random().toString(),
      textualData: menu.textualData,
      validityPeriods: menu.validityPeriods,
      foodItemIds: menu.foodItemIds,
      photoUrl: menu.photoUrl || null
    };
    
    menus.push(newMenu);
    console.log('Created menu:', newMenu);
    return newMenu;
  },

  get: async (id: string) => {
    console.log('Getting menu:', id);
    return menus.find(menu => menu.id === id);
  },

  update: async (id: string, menu: any) => {
    console.log('Updating menu:', id, menu);
    const index = menus.findIndex(m => m.id === id);
    if (index === -1) throw new Error('Menu not found');
    
    const updatedMenu = {
      id,
      textualData: menu.textualData,
      validityPeriods: menu.validityPeriods,
      foodItemIds: menu.foodItemIds,
      photoUrl: menu.photoUrl || null
    };
    
    menus[index] = updatedMenu;
    return updatedMenu;
  },

  delete: async (id: string) => {
    console.log('Deleting menu:', id);
    menus = menus.filter(menu => menu.id !== id);
    return true;
  }
};