import { Promotion } from "@/types";

export const mockPromotions: Promotion[] = [
  {
    id: "1",
    status: "scheduled",
    dateRange: "Dec 24-26 • 11:00-22:00",
    validityPeriod: {
      startDay: "--12-24",
      endDay: "--12-26",
      startTime: "11:00:00",
      endTime: "22:00:00",
      zoneId: "CET"
    },
    foodItemIds: ["1", "2"],
    fixedPrice: 39.90,
    photoUrl: null
  },
  {
    id: "2",
    status: "active",
    dateRange: "MO-FR 11:00-15:00",
    validityPeriod: {
      startTime: "11:00:00",
      endTime: "15:00:00",
      weekDays: ["MO", "TU", "WE", "TH", "FR"],
      zoneId: "CET"
    },
    foodItemIds: ["1"],
    reduction: 15,
    photoUrl: null
  }
];