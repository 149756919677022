import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Calendar, ChevronLeft, Info, Utensils } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Badge } from "@/components/ui/badge";
import { ValidityPeriodDisplay } from "@/components/shared/ValidityPeriodDisplay";
import { WeekDay } from "@/types";

export default function ViewPromotion() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: promotion, isLoading } = useQuery({
    queryKey: ['promotions', id],
    queryFn: () => mockStaffClient.getPromotion(id!),
  });

  const { data: foodItems } = useQuery({
    queryKey: ['foodItems'],
    queryFn: () => mockStaffClient.listFoodItems(),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!promotion) {
    return <div>Promotion not found</div>;
  }

  const getFoodItemNames = () => {
    return promotion.foodItemIds
      .map(itemId => foodItems?.find(item => item.id === itemId)?.textualData[0].title)
      .filter(Boolean);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <Button variant="outline" onClick={() => navigate("/promotions")}>
          <ChevronLeft className="h-4 w-4 mr-2" />
          Back to Promotions
        </Button>
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">Promotion Details</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <Info className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p>Detailed view of the promotion including its validity period and included items.</p>
            </HoverCardContent>
          </HoverCard>
        </div>
      </div>

      <Card>
        <CardContent className="p-6 space-y-6">
          <div className="flex gap-6">
            {promotion.photoUrl ? (
              <img
                src={promotion.photoUrl}
                alt="Promotion"
                className="w-48 h-48 object-cover rounded-lg"
              />
            ) : (
              <div className="w-48 h-48 bg-gray-100 rounded-lg flex items-center justify-center">
                <Utensils className="w-12 h-12 text-gray-400" />
              </div>
            )}
            <div className="space-y-4 flex-1">
              <div className="flex gap-3">
                <Badge variant={promotion.status === "active" ? "default" : "secondary"}>
                  {promotion.status === "active" ? "Active" : "Scheduled"}
                </Badge>
                <Badge variant="outline">
                  <Calendar className="w-4 h-4 mr-2" />
                  <ValidityPeriodDisplay
                    weekDays={promotion.validityPeriod.weekDays as WeekDay[]}
                    startDay={promotion.validityPeriod.startDay}
                    endDay={promotion.validityPeriod.endDay}
                    startTime={promotion.validityPeriod.startTime}
                    endTime={promotion.validityPeriod.endTime}
                  />
                </Badge>
              </div>
              <div>
                {promotion.fixedPrice ? (
                  <Badge className="text-lg">Fixed price €{promotion.fixedPrice.toFixed(2)}</Badge>
                ) : promotion.reduction ? (
                  <Badge className="text-lg">{promotion.reduction}% off</Badge>
                ) : null}
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-3">Included Items</h3>
            <div className="grid gap-3">
              {getFoodItemNames().map((itemName, index) => (
                <div key={index} className="p-4 border rounded-lg">
                  <h4 className="font-medium">{itemName}</h4>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
