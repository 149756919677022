import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MockAuthProvider } from "@/auth/mockAuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LanguageProvider } from "@/contexts/LanguageContext";
import StaffLayout from "./components/layout/StaffLayout";
import RequireAuth from "./components/auth/RequireAuth";
import Dashboard from "./pages/Dashboard";
import PromotionsIndex from "./pages/promotions/Index";
import CreatePromotion from "./pages/CreatePromotion";
import ViewPromotion from "./pages/promotions/ViewPromotion";
import CategoriesIndex from "./pages/categories/Index";
import CreateCategory from "./pages/categories/CreateCategory";
import ViewCategory from "./pages/categories/ViewCategory";
import FoodItemsIndex from "./pages/food-items/Index";
import CreateFoodItem from "./pages/food-items/CreateFoodItem";
import ViewFoodItem from "./pages/food-items/ViewFoodItem";
import MenusIndex from "./pages/menus/Index";
import CreateMenu from "./pages/menus/CreateMenu";
import ViewMenu from "./pages/menus/ViewMenu";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      retry: 1,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <MockAuthProvider>
      <LanguageProvider initialDefaultLanguage="EN">
        <TooltipProvider>
          <Toaster />
          <Sonner />
          <BrowserRouter>
            <Routes>
            {/* Auth routes */}
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            
            {/* Protected routes */}
            <Route path="/" element={
              <RequireAuth>
                <StaffLayout>
                  <Dashboard />
                </StaffLayout>
              </RequireAuth>
            } />
            
            {/* Promotions routes */}
            <Route path="/promotions" element={
              <RequireAuth>
                <StaffLayout>
                  <PromotionsIndex />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/promotions/create" element={
              <RequireAuth>
                <StaffLayout>
                  <CreatePromotion />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/promotions/:id" element={
              <RequireAuth>
                <StaffLayout>
                  <ViewPromotion />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/promotions/:id/edit" element={
              <RequireAuth>
                <StaffLayout>
                  <CreatePromotion />
                </StaffLayout>
              </RequireAuth>
            } />

            {/* Categories routes */}
            <Route path="/categories" element={
              <RequireAuth>
                <StaffLayout>
                  <CategoriesIndex />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/categories/create" element={
              <RequireAuth>
                <StaffLayout>
                  <CreateCategory />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/categories/:id" element={
              <RequireAuth>
                <StaffLayout>
                  <ViewCategory />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/categories/:id/edit" element={
              <RequireAuth>
                <StaffLayout>
                  <CreateCategory />
                </StaffLayout>
              </RequireAuth>
            } />

            {/* Food Items routes */}
            <Route path="/food-items" element={
              <RequireAuth>
                <StaffLayout>
                  <FoodItemsIndex />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/food-items/create" element={
              <RequireAuth>
                <StaffLayout>
                  <CreateFoodItem />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/food-items/:id" element={
              <RequireAuth>
                <StaffLayout>
                  <ViewFoodItem />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/food-items/:id/edit" element={
              <RequireAuth>
                <StaffLayout>
                  <CreateFoodItem />
                </StaffLayout>
              </RequireAuth>
            } />

            {/* Menus routes */}
            <Route path="/menus" element={
              <RequireAuth>
                <StaffLayout>
                  <MenusIndex />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/menus/create" element={
              <RequireAuth>
                <StaffLayout>
                  <CreateMenu />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/menus/:id" element={
              <RequireAuth>
                <StaffLayout>
                  <ViewMenu />
                </StaffLayout>
              </RequireAuth>
            } />
            <Route path="/menus/:id/edit" element={
              <RequireAuth>
                <StaffLayout>
                  <CreateMenu />
                </StaffLayout>
              </RequireAuth>
            } />

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </TooltipProvider>
      </LanguageProvider>
    </MockAuthProvider>
  </QueryClientProvider>
);

export default App;
