import { FoodItemFormValues } from "@/types";
import { mockFoodItems as initialFoodItems } from "../mockData/foodItems";

let foodItems = [...initialFoodItems];

export const foodItemsClient = {
  list: async () => {
    console.log('Listing food items:', foodItems);
    return foodItems;
  },

  create: async (foodItem: FoodItemFormValues) => {
    console.log('Creating food item:', foodItem);
    const newFoodItem = {
      id: Math.random().toString(),
      textualData: foodItem.textualData,
      price: { amount: Math.round(parseFloat(foodItem.price) * 100), currency: "EUR" as const },
      categoryIds: foodItem.categoryIds,
      photoUrl: foodItem.photoUrls[0] || null,
      allergenFree: !foodItem.allergens?.length,
      allergens: foodItem.allergens || [],
      dietaryClassification: "OMNIVOROUS" as const,
      variations: foodItem.variations.map(v => ({
        textualData: v.textualData,
        price: { amount: Math.round(parseFloat(v.price) * 100), currency: "EUR" as const },
        options: v.options.map(o => ({
          textualData: o.textualData,
          price: { amount: Math.round(parseFloat(o.price) * 100), currency: "EUR" as const }
        }))
      }))
    };
    
    foodItems.push(newFoodItem);
    console.log('Created food item:', newFoodItem);
    return newFoodItem;
  },

  get: async (id: string) => {
    console.log('Getting food item:', id);
    return foodItems.find(item => item.id === id);
  },

  update: async (id: string, foodItem: FoodItemFormValues) => {
    console.log('Updating food item:', id, foodItem);
    const index = foodItems.findIndex(item => item.id === id);
    if (index === -1) throw new Error('Food item not found');
    
    const updatedFoodItem = {
      id,
      textualData: foodItem.textualData,
      price: { amount: Math.round(parseFloat(foodItem.price) * 100), currency: "EUR" as const },
      categoryIds: foodItem.categoryIds,
      photoUrl: foodItem.photoUrls[0] || null,
      allergenFree: !foodItem.allergens?.length,
      allergens: foodItem.allergens || [],
      dietaryClassification: "OMNIVOROUS" as const,
      variations: foodItem.variations.map(v => ({
        textualData: v.textualData,
        price: { amount: Math.round(parseFloat(v.price) * 100), currency: "EUR" as const },
        options: v.options.map(o => ({
          textualData: o.textualData,
          price: { amount: Math.round(parseFloat(o.price) * 100), currency: "EUR" as const }
        }))
      }))
    };
    
    foodItems[index] = updatedFoodItem;
    return updatedFoodItem;
  },

  delete: async (id: string) => {
    console.log('Deleting food item:', id);
    foodItems = foodItems.filter(item => item.id !== id);
    return true;
  }
};