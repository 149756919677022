import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Wand2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";
import { RunwareService } from "@/utils/runwareService";
import { useToast } from "@/components/ui/use-toast";

interface AIGenerationDialogProps {
  onImageGenerated: (imageUrl: string) => Promise<void>;
  disabled?: boolean;
}

export function AIGenerationDialog({ onImageGenerated, disabled }: AIGenerationDialogProps) {
  const [showDialog, setShowDialog] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslation();
  const { toast } = useToast();

  const handleGenerate = async () => {
    if (!aiPrompt.trim()) return;

    try {
      setIsGenerating(true);
      const runware = new RunwareService("YOUR_API_KEY");
      const result = await runware.generateImage({
        positivePrompt: aiPrompt,
        numberResults: 1,
      });

      if (result.imageURL) {
        await onImageGenerated(result.imageURL);
        setShowDialog(false);
      }
    } catch (error) {
      console.error('AI generation error:', error);
      toast({
        title: t('forms.errors.aiGenerationFailed'),
        description: t('forms.errors.tryAgain'),
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogTrigger asChild>
        <Button variant="outline" type="button" disabled={disabled}>
          <Wand2 className="w-4 h-4 mr-2" />
          {t('forms.buttons.generateAI')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('forms.titles.generateImage')}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <Input
            placeholder={t('forms.placeholders.aiPrompt')}
            value={aiPrompt}
            onChange={(e) => setAiPrompt(e.target.value)}
          />
          <Button 
            onClick={handleGenerate} 
            disabled={isGenerating || !aiPrompt.trim()}
          >
            {isGenerating ? t('forms.buttons.generating') : t('forms.buttons.generate')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}