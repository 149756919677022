import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ChevronLeft, Info, DollarSign, Tag, Layers, Utensils } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Badge } from "@/components/ui/badge";
import { useLocalizedText } from "@/utils/textual";
import { useTranslation } from "@/hooks/useTranslation";

export default function ViewFoodItem() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const getLocalizedText = useLocalizedText();
  const { t } = useTranslation();

  const { data: foodItem, isLoading } = useQuery({
    queryKey: ['foodItems', id],
    queryFn: () => mockStaffClient.getFoodItem(id!),
  });

  const { data: categories = [] } = useQuery({
    queryKey: ['categories'],
    queryFn: () => mockStaffClient.listCategories(),
  });

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  if (!foodItem) {
    return <div>{t('common.notFound')}</div>;
  }

  const { title, description } = getLocalizedText(foodItem.textualData);
  const price = (foodItem.price.amount / 100).toFixed(2);
  const categoryNames = categories
    .filter(cat => foodItem.categoryIds.includes(cat.id))
    .map(cat => getLocalizedText(cat.textualData).title);

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <Button variant="outline" onClick={() => navigate("/food-items")}>
          <ChevronLeft className="h-4 w-4 mr-2" />
          {t('navigation.backToFoodItems')}
        </Button>
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">{title}</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <Info className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p>{t('pages.foodItems.details.description')}</p>
            </HoverCardContent>
          </HoverCard>
        </div>
      </div>

      <Card>
        <CardContent className="p-6 space-y-6">
          <div className="flex gap-6">
            {foodItem.photoUrl ? (
              <img
                src={foodItem.photoUrl}
                alt={title}
                className="w-48 h-48 object-cover rounded-lg"
              />
            ) : (
              <div className="w-48 h-48 bg-gray-100 rounded-lg flex items-center justify-center">
                <Utensils className="w-12 h-12 text-gray-400" />
              </div>
            )}
            <div className="space-y-4 flex-1">
              <div>
                <h2 className="text-xl font-semibold">{title}</h2>
                <p className="text-muted-foreground mt-1">{description}</p>
              </div>
              <div className="flex flex-wrap gap-2">
                <Badge variant="secondary" className="flex items-center gap-1 bg-[#9b87f5] text-white">
                  <DollarSign className="h-3 w-3" />
                  €{price}
                </Badge>
                {categoryNames.map((name, index) => (
                  <Badge key={index} variant="secondary" className="flex items-center gap-1 bg-[#7E69AB] text-white">
                    <Tag className="h-3 w-3" />
                    {name}
                  </Badge>
                ))}
                {foodItem.variations.length > 0 && (
                  <Badge variant="secondary" className="flex items-center gap-1 bg-[#6E59A5] text-white">
                    <Layers className="h-3 w-3" />
                    {foodItem.variations.length} {t('common.variations')}
                  </Badge>
                )}
              </div>
            </div>
          </div>

          {foodItem.variations.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-3">{t('pages.foodItems.details.variations')}</h3>
              <div className="grid gap-3">
                {foodItem.variations.map((variation, index) => {
                  const { title: variationTitle, description: variationDescription } = getLocalizedText(variation.textualData);
                  return (
                    <div key={index} className="p-4 border rounded-lg">
                      <div className="flex justify-between items-center">
                        <div>
                          <h4 className="font-medium">{variationTitle}</h4>
                          {variationDescription && (
                            <p className="text-sm text-muted-foreground">
                              {variationDescription}
                            </p>
                          )}
                        </div>
                        <Badge>€{(variation.price.amount / 100).toFixed(2)}</Badge>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}