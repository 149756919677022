import { Button } from "@/components/ui/button";
import { FormLabel } from "@/components/ui/form";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { Control } from "react-hook-form";
import { Plus, Trash2, Edit } from "lucide-react";
import { useFieldArray } from "react-hook-form";
import { useState } from "react";
import { AdditionDialog } from "./AdditionDialog";
import { CategoryFormValues } from "@/types";
import { useTranslation } from "@/hooks/useTranslation";

interface AdditionsSectionProps {
  control: Control<CategoryFormValues>;
}

export function AdditionsSection({ control }: AdditionsSectionProps) {
  const { t } = useTranslation();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "additions",
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleAdditionSubmit = (values: any) => {
    if (editingIndex !== null) {
      update(editingIndex, values);
      setEditingIndex(null);
    } else {
      append(values);
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setDialogOpen(true);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <FormLabel>{t('forms.labels.additions')}</FormLabel>
        <InfoTooltip content={t('forms.tooltips.additions')} />
      </div>

      {fields.map((field, index) => {
        const addition = field as any;
        return (
          <div key={field.id} className="p-4 border rounded-lg space-y-4">
            <div className="flex justify-between items-start">
              <div>
                <h4 className="text-sm font-medium">
                  {addition.title_en || t('forms.labels.additions') + ` ${index + 1}`}
                </h4>
                {addition.description_en && (
                  <p className="text-sm text-muted-foreground">{addition.description_en}</p>
                )}
                <p className="text-sm">€{addition.price}</p>
              </div>
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleEdit(index)}
                >
                  <Edit className="h-4 w-4" />
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => remove(index)}
                  className="text-destructive"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        );
      })}

      <Button
        type="button"
        variant="outline"
        onClick={() => {
          setEditingIndex(null);
          setDialogOpen(true);
        }}
        className="w-full"
      >
        <Plus className="h-4 w-4 mr-2" />
        {t('forms.labels.additions')}
      </Button>

      <AdditionDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        onSubmit={handleAdditionSubmit}
        initialData={editingIndex !== null ? fields[editingIndex] as any : undefined}
      />
    </div>
  );
}