import { MultilingualInput } from "@/components/shared/MultilingualInput";
import { PhotoUpload } from "@/components/shared/PhotoUpload";
import { Control, useFormContext } from "react-hook-form";
import { CategoryFormValues } from "@/types";
import { useTranslation } from "@/hooks/useTranslation";

interface BasicInfoFieldsProps {
  control: Control<CategoryFormValues>;
  initialData?: CategoryFormValues;
}

export function BasicInfoFields({ control, initialData }: BasicInfoFieldsProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext<CategoryFormValues>();

  return (
    <>
      <MultilingualInput
        control={control}
        fieldName="textualData"
        label={t('forms.labels.title')}
        tooltip={t('forms.tooltips.title')}
        placeholderTitle={t('forms.placeholders.title')}
        placeholderDescription={t('forms.placeholders.description')}
        multiline
      />

      <PhotoUpload
        itemId={initialData?.id}
        itemType="categories"
        onPhotoUploaded={(url) => setValue("photoUrl", url)}
        existingPhotos={initialData?.photoUrl ? [initialData.photoUrl] : []}
        onPhotoRemoved={() => setValue("photoUrl", "")}
      />
    </>
  );
}