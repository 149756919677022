import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { TimeFields } from "./form-sections/TimeFields";
import { WeekDaysField } from "./form-sections/WeekDaysField";
import { mockStaffClient } from "@/api/mockStaffClient";
import { PhotoUpload } from "@/components/shared/PhotoUpload";
import { useState } from "react";
import { FoodItemsField } from "./form-sections/FoodItemsField";
import { PricingField } from "./form-sections/PricingField";
import { useTranslation } from "@/hooks/useTranslation";

const weekDays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"] as const;

const formSchema = z.object({
  foodItemIds: z.array(z.string()).min(1, "At least one food item must be selected"),
  fixedPrice: z.number().optional(),
  reduction: z.number().min(0).max(100).optional(),
  startDay: z.string().optional(),
  endDay: z.string().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  weekDays: z.array(z.enum(weekDays)).optional(),
});

export type PromotionFormValues = z.infer<typeof formSchema>;

export function PromotionForm() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [promotionId, setPromotionId] = useState<string>();
  const [photos, setPhotos] = useState<string[]>([]);
  
  const form = useForm<PromotionFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startDay: "",
      endDay: "",
      startTime: "",
      endTime: "",
      weekDays: [],
      foodItemIds: [],
      fixedPrice: undefined,
      reduction: undefined,
    },
  });

  async function onSubmit(values: PromotionFormValues) {
    console.log("Form submitted with values:", values);
    
    try {
      const formatToApiDate = (dateStr: string | undefined) => {
        if (!dateStr) return undefined;
        const date = new Date(dateStr);
        return `--${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      };

      const formatToApiTime = (timeStr: string | undefined) => {
        if (!timeStr) return undefined;
        return `${timeStr}:00`;
      };

      const apiData = {
        validityPeriod: {
          startDay: formatToApiDate(values.startDay),
          endDay: formatToApiDate(values.endDay),
          startTime: formatToApiTime(values.startTime),
          endTime: formatToApiTime(values.endTime),
          weekDays: values.weekDays,
          zoneId: "CET" as const,
        },
        foodItemIds: values.foodItemIds,
        fixedPrice: values.fixedPrice,
        reduction: values.reduction,
        photoUrl: photos[0] || null,
      };

      console.log("Formatted API data:", apiData);

      const result = await mockStaffClient.createPromotion(apiData);
      setPromotionId(result.id);

      toast({
        title: t('promotions.created'),
        description: t('promotions.createSuccess'),
      });
      navigate("/promotions");
    } catch (error) {
      console.error('Error creating promotion:', error);
      toast({
        title: t('common.error'),
        description: t('promotions.createError'),
        variant: "destructive",
      });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <TimeFields control={form.control} />
        </div>
        <WeekDaysField control={form.control} />
        <FoodItemsField control={form.control} />
        <PricingField control={form.control} />
        <PhotoUpload
          itemId={promotionId}
          itemType="promotions"
          onPhotoUploaded={(url) => setPhotos([url])}
          existingPhotos={photos}
          onPhotoRemoved={() => setPhotos([])}
        />
        
        <div className="flex justify-end gap-4">
          <Button variant="outline" type="button" onClick={() => navigate("/promotions")}>
            {t('common.cancel')}
          </Button>
          <Button type="submit">{t('common.create')}</Button>
        </div>
      </form>
    </Form>
  );
}