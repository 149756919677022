import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { MultilingualInput } from "@/components/shared/MultilingualInput";
import { Input } from "@/components/ui/input";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { useTranslation } from "@/hooks/useTranslation";

const additionSchema = z.object({
  title_en: z.string().min(1, "English title is required"),
  title_de: z.string().optional(),
  title_fr: z.string().optional(),
  title_it: z.string().optional(),
  description_en: z.string().optional(),
  description_de: z.string().optional(),
  description_fr: z.string().optional(),
  description_it: z.string().optional(),
  price: z.string().min(1, "Price is required").regex(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
});

interface AdditionDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (values: any) => void;
  initialData?: any;
}

export function AdditionDialog({ open, onOpenChange, onSubmit, initialData }: AdditionDialogProps) {
  const { t } = useTranslation();
  const form = useForm({
    resolver: zodResolver(additionSchema),
    defaultValues: initialData || {
      title_en: "",
      title_de: "",
      title_fr: "",
      title_it: "",
      description_en: "",
      description_de: "",
      description_fr: "",
      description_it: "",
      price: "",
    },
  });

  const handleSubmit = (values: any) => {
    onSubmit(values);
    onOpenChange(false);
    form.reset();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>{t('forms.labels.additions')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            <MultilingualInput
              control={form.control}
              fieldName="title"
              label={t('forms.labels.title')}
              tooltip={t('forms.tooltips.description')}
              placeholderTitle={t('forms.placeholders.description')}
              multiline
            />

            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center gap-2">
                    {t('forms.labels.price')}
                    <InfoTooltip content={t('forms.tooltips.price')} />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder={t('forms.placeholders.price')} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4 pt-4">
              <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
                {t('common.cancel')}
              </Button>
              <Button type="submit">{t('common.submit')}</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}