import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FoodItemForm } from "@/components/food-items/FoodItemForm";
import { useTranslation } from "@/hooks/useTranslation";

export default function CreateFoodItem() {
  const { t } = useTranslation();

  return (
    <div className="max-w-2xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>{t('foodItems.create')}</CardTitle>
        </CardHeader>
        <CardContent>
          <FoodItemForm />
        </CardContent>
      </Card>
    </div>
  );
}