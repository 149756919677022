export const de = {
  common: {
    loading: "Wird geladen...",
    error: "Fehler",
    delete: "Löschen",
    edit: "Bearbeiten",
    view: "Ansehen",
    create: "Erstellen",
    cancel: "Abbrechen",
    save: "Speichern",
    back: "Zurück",
    required: "Pflichtfeld",
    optional: "Optional",
    submit: "Absenden",
    confirmDelete: "Sind Sie sicher, dass Sie dies löschen möchten?",
  },
  forms: {
    validation: {
      required: "Dieses Feld ist erforderlich",
      invalidPrice: "Ungültiges Preisformat",
      invalidDate: "Ungültiges Datumsformat",
      invalidTime: "Ungültiges Zeitformat",
    },
    labels: {
      title: "Titel",
      description: "Beschreibung",
      price: "Preis",
      photo: "Foto",
      category: "Kategorie",
      categories: "Kategorien",
      allergens: "Allergene",
      variations: "Variationen",
      options: "Optionen",
      additions: "Zusätze",
      startDate: "Startdatum",
      endDate: "Enddatum",
      startTime: "Startzeit",
      endTime: "Endzeit",
      weekDays: "Wochentage",
    },
    tooltips: {
      title: "Geben Sie einen beschreibenden Titel ein",
      description: "Geben Sie detaillierte Informationen an",
      price: "Preis in Euro eingeben (z.B. 12,90)",
      photo: "Foto hochladen",
      category: "Relevante Kategorien auswählen",
      allergens: "Alle zutreffenden Allergene auswählen",
      variations: "Verschiedene Versionen mit eigenen Preisen hinzufügen",
      options: "Optionen für diese Variation hinzufügen",
      additions: "Optionale Extras hinzufügen",
      validity: "Festlegen, wann dies gültig ist",
    },
    placeholders: {
      title: "Titel eingeben",
      description: "Beschreibung eingeben",
      price: "0,00",
      search: "Suchen...",
    },
  },
  menus: {
    title: "Menüs",
    description: "Sammlungen von Speisen, die in Menüs organisiert sind. Jedes Menü kann mehrere Artikel und Kategorien enthalten.",
    create: "Menü erstellen",
    edit: "Menü bearbeiten",
    delete: "Menü löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie dieses Menü löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    items: "Artikel",
    updated: "Menü aktualisiert",
    created: "Menü erstellt",
    updateSuccess: "Ihr Menü wurde erfolgreich aktualisiert.",
    createSuccess: "Ihr Menü wurde erfolgreich erstellt.",
    loadError: "Fehler beim Laden der Menüdaten. Bitte versuchen Sie es erneut.",
    updateError: "Fehler beim Aktualisieren des Menüs. Bitte versuchen Sie es erneut.",
    createError: "Fehler beim Erstellen des Menüs. Bitte versuchen Sie es erneut.",
  },
  categories: {
    title: "Kategorien",
    description: "Kategorien helfen bei der Organisation von Menüpunkten in logische Gruppen für einfachere Navigation und Verwaltung.",
    create: "Kategorie erstellen",
    edit: "Kategorie bearbeiten",
    delete: "Kategorie löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Kategorie löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    additions: "Zusätze",
    updated: "Kategorie aktualisiert",
    created: "Kategorie erstellt",
    updateSuccess: "Kategorie wurde erfolgreich aktualisiert.",
    createSuccess: "Kategorie wurde erfolgreich erstellt.",
    loadError: "Fehler beim Laden der Kategoriedaten. Bitte versuchen Sie es erneut.",
  },
  foodItems: {
    title: "Speisen",
    description: "Einzelne Speisen, die zu Menüs und Aktionen hinzugefügt werden können. Jeder Artikel enthält Details wie Preis, Beschreibung und Kategorie.",
    create: "Speise erstellen",
    edit: "Speise bearbeiten",
    delete: "Speise löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Speise löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    variations: "Variationen",
    updated: "Speise aktualisiert",
    created: "Speise erstellt",
    updateSuccess: "Speise wurde erfolgreich aktualisiert.",
    createSuccess: "Speise wurde erfolgreich erstellt.",
    loadError: "Fehler beim Laden der Speisedaten. Bitte versuchen Sie es erneut.",
  },
  promotions: {
    title: "Aktionen",
    description: "Aktionen definieren zeitlich begrenzte Angebote für Artikel, wenn sie zusammen bestellt werden. Angebote können Festpreis oder Prozentrabatt sein.",
    create: "Aktion erstellen",
    edit: "Aktion bearbeiten",
    delete: "Aktion löschen",
    deleteConfirm: "Sind Sie sicher, dass Sie diese Aktion löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    status: {
      active: "Aktiv",
      scheduled: "Geplant"
    },
    updated: "Aktion aktualisiert",
    created: "Aktion erstellt",
    updateSuccess: "Aktion wurde erfolgreich aktualisiert.",
    createSuccess: "Aktion wurde erfolgreich erstellt.",
    loadError: "Fehler beim Laden der Aktionsdaten. Bitte versuchen Sie es erneut.",
  },
  dashboard: {
    title: "Dashboard",
    description: "Überblick über die wichtigsten Kennzahlen und die jüngsten Aktivitäten Ihres Restaurants.",
    welcome: "Willkommen im Gasto Staff Portal",
  }
};
