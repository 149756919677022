import { CategoryFormValues } from "@/types";
import { mockCategories as initialCategories } from "../mockData/categories";

let categories = [...initialCategories];

export const categoriesClient = {
  list: async () => {
    console.log('Listing categories:', categories);
    return categories;
  },

  create: async (category: CategoryFormValues) => {
    console.log('Creating category:', category);
    const newCategory = {
      id: Math.random().toString(),
      textualData: category.textualData,
      photoUrl: category.photoUrl || null,
      additions: category.additions.map(addition => ({
        textualData: addition.textualData,
        price: {
          amount: Math.round(parseFloat(addition.price) * 100),
          currency: "EUR" as const
        }
      }))
    };
    
    categories.push(newCategory);
    console.log('Created category:', newCategory);
    return newCategory;
  },

  get: async (id: string) => {
    console.log('Getting category:', id);
    return categories.find(cat => cat.id === id);
  },

  update: async (id: string, category: CategoryFormValues) => {
    console.log('Updating category:', id, category);
    const index = categories.findIndex(cat => cat.id === id);
    if (index === -1) throw new Error('Category not found');
    
    const updatedCategory = {
      id,
      textualData: category.textualData,
      photoUrl: category.photoUrl || null,
      additions: category.additions.map(addition => ({
        textualData: addition.textualData,
        price: {
          amount: Math.round(parseFloat(addition.price) * 100),
          currency: "EUR" as const
        }
      }))
    };
    
    categories[index] = updatedCategory;
    return updatedCategory;
  },

  delete: async (id: string) => {
    console.log('Deleting category:', id);
    categories = categories.filter(cat => cat.id !== id);
    return true;
  }
};