import { useQuery } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BarChart, Calendar, TrendingUp, Utensils } from "lucide-react";
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function Dashboard() {
  const { data: stats, isLoading: statsLoading } = useQuery({
    queryKey: ['stats'],
    queryFn: () => mockStaffClient.getStats()
  });

  const { data: menus, isLoading: menusLoading } = useQuery({
    queryKey: ['menus'],
    queryFn: () => mockStaffClient.listMenus()
  });

  const { data: promotions, isLoading: promotionsLoading } = useQuery({
    queryKey: ['promotions'],
    queryFn: () => mockStaffClient.listPromotions()
  });

  const activeMenus = menus?.filter(menu => {
    // In a real app, we would check the validity periods
    return true;
  }) || [];

  const activePromotions = promotions?.filter(promo => 
    promo.status === 'active'
  ) || [];

  if (statsLoading || menusLoading || promotionsLoading) {
    return <div>Loading...</div>;
  }

  const formatOrderData = (items: any[]) => {
    return items.map(item => ({
      name: item.title,
      orders: item.orders
    }));
  };

  const getPromotionDescription = (promotion: typeof promotions[0]) => {
    if (promotion.fixedPrice) {
      return `Fixed price €${promotion.fixedPrice.toFixed(2)}`;
    }
    if (promotion.reduction) {
      return `${promotion.reduction}% off`;
    }
    return "";
  };

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Dashboard</h1>

      {/* Active Menus and Promotions */}
      <div className="grid gap-4 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Utensils className="h-4 w-4" />
              Active Menus
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {activeMenus.map(menu => (
                <div key={menu.id} className="flex items-center justify-between p-2 bg-muted rounded-lg">
                  <span>{menu.textualData[0].title}</span>
                  <span className="text-sm text-muted-foreground">
                    {menu.foodItemIds?.length || 0} items
                  </span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <TrendingUp className="h-4 w-4" />
              Active Promotions
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {activePromotions.map(promo => (
                <div key={promo.id} className="flex items-center justify-between p-2 bg-muted rounded-lg">
                  <span>{`${promo.foodItemIds.length} items`}</span>
                  <span className="text-sm text-muted-foreground">
                    {promo.dateRange}
                  </span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Statistics */}
      <div className="grid gap-4">
        {/* Food Items Stats */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <BarChart className="h-4 w-4" />
              Food Items Statistics
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4 md:grid-cols-2">
              <div>
                <h4 className="mb-4 text-sm font-medium flex items-center gap-2">
                  <Calendar className="h-4 w-4" />
                  Most Ordered Today
                </h4>
                <div className="h-[200px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <RechartsBarChart data={formatOrderData(stats.foodItems.today)}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="orders" fill="#22c55e" />
                    </RechartsBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div>
                <h4 className="mb-4 text-sm font-medium flex items-center gap-2">
                  <TrendingUp className="h-4 w-4" />
                  Most Ordered This Year
                </h4>
                <div className="h-[200px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <RechartsBarChart data={formatOrderData(stats.foodItems.yearly)}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="orders" fill="#3b82f6" />
                    </RechartsBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Categories Stats */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <BarChart className="h-4 w-4" />
              Categories Statistics
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4 md:grid-cols-2">
              <div>
                <h4 className="mb-4 text-sm font-medium flex items-center gap-2">
                  <Calendar className="h-4 w-4" />
                  Most Ordered Today
                </h4>
                <div className="h-[200px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <RechartsBarChart data={formatOrderData(stats.categories.today)}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="orders" fill="#22c55e" />
                    </RechartsBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div>
                <h4 className="mb-4 text-sm font-medium flex items-center gap-2">
                  <TrendingUp className="h-4 w-4" />
                  Most Ordered This Year
                </h4>
                <div className="h-[200px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <RechartsBarChart data={formatOrderData(stats.categories.yearly)}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="orders" fill="#3b82f6" />
                    </RechartsBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}