import { LocalizedTitleAndDescription } from "@/types";

export const mockFoodItems = [
  {
    id: "1",
    textualData: [
      {
        language: "EN" as const,
        title: "Spaghetti Carbonara",
        description: "Classic Italian pasta dish"
      }
    ] as LocalizedTitleAndDescription[],
    price: { amount: 1590, currency: "EUR" as const },
    categoryIds: ["1"],
    photoUrl: null,
    allergenFree: false,
    allergens: ["EGGS", "MILK"],
    dietaryClassification: "OMNIVOROUS" as const,
    variations: [
      {
        textualData: [
          {
            language: "EN" as const,
            title: "Regular Size",
            description: "Standard portion"
          }
        ] as LocalizedTitleAndDescription[],
        price: { amount: 1590, currency: "EUR" as const },
        options: []
      },
      {
        textualData: [
          {
            language: "EN" as const,
            title: "Large Size",
            description: "50% more pasta"
          }
        ] as LocalizedTitleAndDescription[],
        price: { amount: 2190, currency: "EUR" as const },
        options: []
      }
    ]
  },
  {
    id: "2",
    textualData: [
      {
        language: "EN" as const,
        title: "Tiramisu",
        description: "Traditional Italian dessert"
      }
    ] as LocalizedTitleAndDescription[],
    price: { amount: 890, currency: "EUR" as const },
    categoryIds: ["2"],
    photoUrl: null,
    allergenFree: false,
    allergens: ["EGGS", "MILK"],
    dietaryClassification: "VEGETARIAN" as const,
    variations: []
  }
];