import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MenuForm } from "@/components/menus/MenuForm";
import { useTranslation } from "@/hooks/useTranslation";

export default function CreateMenu() {
  const { t } = useTranslation();
  
  return (
    <div className="max-w-2xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>{t('menus.create')}</CardTitle>
        </CardHeader>
        <CardContent>
          <MenuForm />
        </CardContent>
      </Card>
    </div>
  );
}