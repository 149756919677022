import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Control } from "react-hook-form";
import { PromotionFormValues } from "../PromotionForm";
import { InfoTooltip } from "./InfoTooltip";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useState } from "react";
import { useTranslation } from "@/hooks/useTranslation";

export function PricingField({ control }: { control: Control<PromotionFormValues> }) {
  const { t } = useTranslation();
  const [pricingType, setPricingType] = useState<"fixed" | "reduction">();

  return (
    <div className="space-y-4">
      <div>
        <FormLabel className="flex items-center gap-2">
          {t('forms.labels.pricingType')}
          <InfoTooltip content={t('forms.tooltips.pricing')} />
        </FormLabel>
        <RadioGroup
          className="grid grid-cols-2 gap-4 mt-2"
          value={pricingType}
          onValueChange={(value: "fixed" | "reduction") => {
            setPricingType(value);
            // Clear the other field when switching
            if (value === "fixed") {
              control._formValues.reduction = undefined;
            } else {
              control._formValues.fixedPrice = undefined;
            }
          }}
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="fixed" id="fixed" />
            <label htmlFor="fixed">{t('forms.labels.fixedPrice')}</label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="reduction" id="reduction" />
            <label htmlFor="reduction">{t('forms.labels.reduction')}</label>
          </div>
        </RadioGroup>
      </div>

      {pricingType === "fixed" && (
        <FormField
          control={control}
          name="fixedPrice"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-2">
                {t('forms.labels.fixedPrice')}
                <InfoTooltip content={t('forms.tooltips.fixedPrice')} />
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  step="0.01"
                  placeholder="39.90"
                  {...field}
                  onChange={e => field.onChange(parseFloat(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {pricingType === "reduction" && (
        <FormField
          control={control}
          name="reduction"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-2">
                {t('forms.labels.reduction')}
                <InfoTooltip content={t('forms.tooltips.reduction')} />
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  min="0"
                  max="100"
                  step="1"
                  placeholder="15"
                  {...field}
                  onChange={e => field.onChange(parseFloat(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </div>
  );
}