import { createContext, useContext, ReactNode, useState } from "react";

export type SupportedLanguage = "EN" | "DE" | "FR" | "IT";

interface LanguageContextType {
  defaultLanguage: SupportedLanguage;
  setDefaultLanguage: (lang: SupportedLanguage) => void;
  uiLanguage: SupportedLanguage;
  setUiLanguage: (lang: SupportedLanguage) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

interface LanguageProviderProps {
  children: ReactNode;
  initialDefaultLanguage?: SupportedLanguage;
}

export function LanguageProvider({ children, initialDefaultLanguage = "EN" }: LanguageProviderProps) {
  const [defaultLanguage, setDefaultLanguage] = useState<SupportedLanguage>(initialDefaultLanguage);
  const [uiLanguage, setUiLanguage] = useState<SupportedLanguage>(initialDefaultLanguage);

  return (
    <LanguageContext.Provider value={{
      defaultLanguage,
      setDefaultLanguage,
      uiLanguage,
      setUiLanguage,
    }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
}