import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { mockStaffClient } from "@/api/mockStaffClient";
import { useQuery } from "@tanstack/react-query";
import { Badge } from "@/components/ui/badge";
import { X, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Control } from "react-hook-form";
import { InfoTooltip } from "@/components/promotions/form-sections/InfoTooltip";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useTranslation } from "@/hooks/useTranslation";

interface FoodItemsFieldProps {
  control: Control<any>;
}

export function FoodItemsField({ control }: FoodItemsFieldProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { data: foodItems = [] } = useQuery({
    queryKey: ["foodItems"],
    queryFn: () => mockStaffClient.listFoodItems(),
  });

  const filteredFoodItems = foodItems.filter(item => {
    const title = item.textualData.find(t => t.language === "EN")?.title || "";
    return title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const getSelectedItemsText = (count: number) => {
    return count > 0 ? `${count} items selected` : t('forms.labels.addFoodItems');
  };

  return (
    <FormField
      control={control}
      name="foodItemIds"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex items-center gap-2">
            {t('forms.labels.foodItems')}
            <InfoTooltip content={t('forms.tooltips.foodItems')} />
          </FormLabel>

          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button
                type="button"
                variant="outline"
                className="w-full justify-start"
              >
                <Plus className="mr-2 h-4 w-4" />
                {getSelectedItemsText(field.value?.length || 0)}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{t('forms.labels.selectFoodItems')}</DialogTitle>
              </DialogHeader>
              
              <div className="space-y-4">
                <Input
                  placeholder={t('forms.placeholders.search')}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="mb-4"
                />
                
                <ScrollArea className="h-[300px] pr-4">
                  <div className="space-y-2">
                    {filteredFoodItems.map((item) => {
                      const title = item.textualData.find(t => t.language === "EN")?.title || "";
                      return (
                        <div
                          key={item.id}
                          className="flex items-center space-x-2 rounded-md border p-2 cursor-pointer hover:bg-accent"
                          onClick={() => {
                            const currentValues = field.value || [];
                            const newValue = currentValues.includes(item.id)
                              ? currentValues.filter((id: string) => id !== item.id)
                              : [...currentValues, item.id];
                            field.onChange(newValue);
                          }}
                        >
                          <div
                            className={`h-4 w-4 border rounded-sm ${
                              field.value?.includes(item.id)
                                ? "bg-primary border-primary"
                                : "border-input"
                            }`}
                          >
                            {field.value?.includes(item.id) && (
                              <X className="h-4 w-4 text-primary-foreground" />
                            )}
                          </div>
                          <span>{title}</span>
                        </div>
                      );
                    })}
                  </div>
                </ScrollArea>
              </div>
            </DialogContent>
          </Dialog>

          <div className="flex flex-wrap gap-2 mt-2">
            {field.value?.map((itemId: string) => {
              const item = foodItems.find((i) => i.id === itemId);
              if (!item) return null;
              const title = item.textualData.find(t => t.language === "EN")?.title || "";
              return (
                <Badge
                  key={itemId}
                  variant="secondary"
                  className="flex items-center gap-1"
                >
                  {title}
                  <X
                    className="h-3 w-3 cursor-pointer"
                    onClick={() => {
                      field.onChange(
                        field.value.filter((id: string) => id !== itemId)
                      );
                    }}
                  />
                </Badge>
              );
            })}
          </div>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}