export async function getUploadUrl(itemId: string, itemType: 'food-items' | 'categories' | 'menus' | 'promotions') {
  const response = await fetch(`/api/${itemType}/${itemId}/photo`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get upload URL');
  }

  return response.json();
}

export async function uploadFile(uploadUrl: string, file: File, method: string) {
  const response = await fetch(uploadUrl, {
    method: method,
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }

  return response;
}