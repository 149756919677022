import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Edit, Info, Plus, Utensils, Trash2, Clock, Eye } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { mockStaffClient } from "@/api/mockStaffClient";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { ValidityPeriodDisplay } from "@/components/shared/ValidityPeriodDisplay";
import { WeekDay } from "@/types";
import { useTranslation } from "@/hooks/useTranslation";

export default function Index() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: menus, isLoading } = useQuery({
    queryKey: ['menus'],
    queryFn: () => mockStaffClient.listMenus()
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => mockStaffClient.deleteMenu(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['menus'] });
      toast({
        title: t('menus.delete'),
        description: "The menu has been deleted successfully."
      });
    },
    onError: (error) => {
      console.error('Error deleting menu:', error);
      toast({
        title: "Error",
        description: t('menus.deleteError'),
        variant: "destructive"
      });
    }
  });

  const handleDelete = async (id: string) => {
    deleteMutation.mutate(id);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-bold">{t('menus.title')}</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <Info className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p>{t('menus.description')}</p>
            </HoverCardContent>
          </HoverCard>
        </div>
        <Button onClick={() => navigate("/menus/create")}>
          <Plus className="mr-2 h-4 w-4" />
          {t('menus.create')}
        </Button>
      </div>

      <div className="grid gap-4">
        {menus?.map((menu) => (
          <Card key={menu.id}>
            <CardContent className="p-6">
              <div className="flex gap-4">
                {menu.photoUrl ? (
                  <img 
                    src={menu.photoUrl} 
                    alt={menu.textualData[0].title}
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                    <Utensils className="w-8 h-8 text-gray-400" />
                  </div>
                )}
                <div className="flex-1">
                  <div className="flex justify-between">
                    <div className="space-y-4">
                      <div>
                        <h3 className="font-semibold text-lg">{menu.textualData[0].title}</h3>
                        <p className="text-muted-foreground mt-1">{menu.textualData[0].description}</p>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        <span className="inline-flex items-center px-3 py-1 rounded-full bg-orange-100 text-orange-700 text-sm">
                          <Utensils className="mr-2 h-4 w-4" />
                          {menu.foodItemIds?.length || 0} {t('menus.items')}
                        </span>
                        <span className="inline-flex items-center px-3 py-1 rounded-full bg-blue-100 text-blue-700 text-sm">
                          <Clock className="mr-2 h-4 w-4" />
                          {menu.validityPeriods?.map((period, index) => (
                            <ValidityPeriodDisplay
                              key={index}
                              weekDays={period.weekDays as WeekDay[]}
                              startDay={period.startDay}
                              endDay={period.endDay}
                              startTime={period.startTime}
                              endTime={period.endTime}
                            />
                          ))}
                        </span>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => navigate(`/menus/${menu.id}`)}
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                      <Button 
                        variant="outline" 
                        size="icon"
                        onClick={() => navigate(`/menus/${menu.id}/edit`)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button variant="outline" size="icon" className="text-destructive">
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>{t('menus.delete')}</AlertDialogTitle>
                            <AlertDialogDescription>
                              {t('menus.deleteConfirm')}
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => handleDelete(menu.id)}
                              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                            >
                              {t('menus.delete')}
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}