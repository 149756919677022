import { Button } from "@/components/ui/button";
import { useTranslation } from "@/hooks/useTranslation";
import { useNavigate } from "react-router-dom";

interface FormActionsProps {
  isEditing: boolean;
}

export function FormActions({ isEditing }: FormActionsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex justify-end gap-4">
      <Button variant="outline" type="button" onClick={() => navigate("/categories")}>
        {t('common.cancel')}
      </Button>
      <Button type="submit">
        {isEditing ? t('categories.edit') : t('categories.create')}
      </Button>
    </div>
  );
}